
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, PasswordCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';

    @Component
    export default class OtpVerification extends Stage {
        // *** Data ***
        passwordCallback: PasswordCallback | null = null;

        codeHint = '';
        codeInvalid = false;
        codeRulesDeactivated = false;
        otpDeliveredTo = '';
        otpMethod = '';
        showInvalidCodeError = false;

        // *** Computed Properties ***
        get code(): string {
            return this.passwordCallback ? this.passwordCallback.getInputValue() as string : '';
        }

        set code(code: string) {
            if (this.passwordCallback) {
                this.passwordCallback.setInputValue(code);
            }
        }

        get rules(): Array<any> {
            return this.codeRulesDeactivated
                ? []
                : [
                    () => !(this.showInvalidCodeError && this.codeInvalid) || this.$t('validation.otp-code-invalid'),
                    (v: any) => !!v || this.$t('validation.required', { label: this.$t('common.otp-verification.code-label-short') })
                ];
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
            this.code = '';
            (this.$refs.code as any).focus();
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data
            this.otpDeliveredTo = '';
            this.otpMethod = '';
            this.passwordCallback = null;

            try {
                this.passwordCallback = this.$util.am.getCallbackOfType<PasswordCallback>(callbacks, CallbackType.PasswordCallback);
            } catch (error) {
                console.error(error);
            }
        }

        onBlur(event: any) {
            this.showInvalidCodeError = false;

            if (event.relatedTarget && (event.relatedTarget.id === 'btn-back' || event.relatedTarget.id === 'resend-link')) {
                this.codeRulesDeactivated = true;
            } else {
                this.codeRulesDeactivated = false;
                // This is needed because the invalid code error needs to be replaced by 'code required' message instead
                // when losing focus.
                this.form.validate();
            }
        }

        onInput() {
            this.codeHint = '';
            this.showInvalidCodeError = false;
            this.codeRulesDeactivated = false;
        }
    };
