
    import Component from 'vue-class-component';
    import { CallbackType, FRCallback, HiddenValueCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';

    @Component
    export default class RiskScore extends Stage {
        // *** Data ***
        riskScores = [];

        // *** Computed Properties ***
        get isBruteForce() {
            return this.riskScores.some((score: any) => score.is_brute_force);
        }

        get isCredentialStuffing() {
            return this.riskScores.some((score: any) => score.is_credential_stuffing);
        }

        get isImpossibleTravel() {
            return this.riskScores.some((score: any) => score.is_impossible_travel);
        }

        get isSuspiciousEmail() {
            return this.riskScores.some((score: any) => score.is_suspicious_email);
        }

        get isSuspiciousIp() {
            return this.riskScores.some((score: any) => score.is_suspicious_ip);
        }

        get isVPN() {
            return this.riskScores.some((score: any) => score.is_verosint_vpn);
        }

        get isRiskDetected() {
            return this.isBruteForce || this.isCredentialStuffing || this.isImpossibleTravel || this.isSuspiciousEmail || this.isSuspiciousIp || this.isVPN;
        }

        get riskSignals() {
            const signals = [];

            if (this.isBruteForce) signals.push('Brute force attack');
            if (this.isCredentialStuffing) signals.push('Credential stuffing');
            if (this.isImpossibleTravel) signals.push('Impossible travel');
            if (this.isSuspiciousEmail) signals.push('Suspicious email');
            if (this.isSuspiciousIp) signals.push('Suspicious IP');
            if (this.isVPN) signals.push('VPN');

            return signals;
        }

        // *** Lifecycle Methods ***
        async created() {
            this.initCallbackData(this.callbacks);

            if (!this.isRiskDetected) {
                console.error('We should not be showing this page. Journey is likely misconfigured.');
                this.next();
            }
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                const hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(callbacks, CallbackType.HiddenValueCallback);
                const value = hiddenValueCallback.getOutputValue();
                this.riskScores = value && value !== 'null' ? JSON.parse(value as string) : [];
            } catch (error) {
                console.error(error);
            }
        }
    }
