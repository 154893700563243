
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, ChoiceCallback as SDKChoiceCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    @Component
    export default class ChoiceCallback extends Callback {
        @Prop()
        callback!: SDKChoiceCallback;

        // *** Computed Properties ***
        get defaultOption(): number {
            return this.callback.getDefaultChoice();
        }

        get choices(): string[] {
            return this.callback.getChoices();
        }

        get prompt(): string {
            return this.callback.getPrompt();
        }

        get selectedChoice(): number {
            return this.callback.getInputValue() as number;
        }

        set selectedChoice(choice: number) {
            this.callback.setChoiceIndex(choice);
        }

        // *** Methods ***
        isDefaultOption(choiceIndex: number): boolean {
            return this.callback.getDefaultChoice() === choiceIndex;
        }

        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.ChoiceCallback;
        }
    }
