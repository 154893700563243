
    import Component, { mixins } from 'vue-class-component';
    import { ModelSync, Prop, Watch } from 'vue-property-decorator';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import ProfileMixin from '@/components/profile/common/ProfileMixin';
    import VCardTitleWithClose from '@/components/profile/common/dialog/VCardTitleWithClose.vue';
    import OtpVerificationCard from '@/components/profile/common/dialog/OtpVerificationCard.vue';

    enum Page {
        None,
        NewContact,
        OtpVerification,
        SelectContactUses,
        ActivateTwoFactor
    }

    @Component({
        inheritAttrs: false,
        components: {
            VCardTitleWithClose,
            OtpVerificationCard
        }
    })
    export default class AddContactDialog extends mixins(ProfileMixin) {
        @ModelSync('showDialog', 'show-dialog-change', { type: Boolean })
        syncedShowDialog!: boolean;

        @Prop({ validator: contactType => Object.values(ContactType).includes(contactType as ContactType), required: true })
        readonly contactType!: ContactType;

        @Prop({ type: Array, required: true })
        readonly contacts!: Contact[];

        // *** Data ***
        ContactType = ContactType; // Make available to html template
        Page = Page; // Make the Page enum available to the html template

        codeInvalid = false;
        currentPage: Page = Page.None;
        enableAlerts = false;
        enableOtp = false;
        // enablePrimaryContact = false;
        newContact: Contact = this.getNewContact();
        newContactText = '';
        showContactRulesError = false;
        submitInProgress = false;
        valid = true;

        // *** Computed Properties ***
        get validation(): Record<string, any> {
            return {
                contactUseRules: [
                    () => (this.enableAlerts || this.enableOtp) || this.$t('validation.mfa-alerts-required')
                ],
                emailRules: [
                    (v: string) => this.isUniqueContact(v) || this.$t('validation.contact-exists')
                ],
                phoneRules: [
                    (v: string) => this.isUniqueContact(v) || this.$t('validation.contact-exists')
                ]
            };
        }

        // *** Watch Methods ***
        @Watch('newContactText')
        onNewContactTextChange(newValue: string) {
            if (this.contactType === ContactType.Email) {
                this.newContact.id = newValue.toLowerCase();
            } else if (this.contactType === ContactType.Sms) {
                this.newContact.id = this.$util.getUnformattedPhone(newValue);
            }
        }

        @Watch('enableAlerts')
        @Watch('enableOtp')
        onContactUseChange() {
            (this.$refs.form as any).validate();
            this.$nextTick(() => {
                this.showContactRulesError = !this.valid;
            });
        }

        // *** Lifecycle Methods ***
        created() {
            this.currentPage = Page.NewContact;
        }

        // *** Methods ***
        closeDialog(): void {
            this.syncedShowDialog = false;
        }

        getNewContact(): Contact {
            return {
                id: null,
                type: this.contactType as string,
                otp: false,
                alert: false
            };
        }

        isUniqueContact(contactId: string): boolean {
            let unformattedContactId = contactId;
            if (this.contactType === ContactType.Sms) {
                unformattedContactId = this.$util.getUnformattedPhone(contactId);
            }
            return !this.contacts.find(contact => contact.id === unformattedContactId);
        }

        async proceedToVerification(): Promise<void> {
            if (!this.valid || this.submitInProgress) {
                (this.$refs.form as any).validate();
                return;
            }

            try {
                this.codeInvalid = false;
                this.currentPage = Page.OtpVerification;
            } catch (error) {
                // Do nothing - don't transition to next page on error
            }
        }

        async proceedToContactUses(code: string): Promise<void> {
            try {
                this.codeInvalid = false;
                this.currentPage = Page.SelectContactUses;
            } catch (error) {
                if (this.isInvalidCodeError(error)) {
                    this.codeInvalid = true;
                }
            }
        }

        // Proceeds to one of two pages, depending on whether 2FA in enabled
        async proceedToNext(): Promise<void> {
            if (!this.valid) {
                this.showContactRulesError = true;
                (this.$refs.form as any).validate();
                return;
            }

            this.newContact.otp = this.enableOtp;
            this.newContact.alert = this.enableAlerts;

            this.showContactRulesError = false;
            await this.createContactReq(this.newContact);
            await this.proceedToFinish();
        }

        async activateTwoFactor(): Promise<void> {
            try {
                // const response = await this.activateVerifiededMfaReq(this.newContact);
                // this.verificationToken = response.data.token;
                // await this.proceedToFinish();
            } catch (error) {
                this.$notification.error({
                    title: this.$t('errors.mfa-profile-start-activation.title') as string,
                    message: this.$t('errors.mfa-profile-start-activation.message') as string
                }, error);
            }
        }

        async proceedToFinish(): Promise<void> {
            try {
                this.$emit('add', this.newContact);
            } catch (error) {
                this.$notification.error({
                    title: this.$t('errors.contact-creation.title') as string,
                    message: this.$t('errors.contact-creation.message') as string
                }, error);
            }

            this.syncedShowDialog = false;
        }
    }
