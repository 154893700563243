
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, NameCallback, PasswordCallback, AttributeInputCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: {
            GenericCallbackRenderer
        }
    })
    export default class UserRegistration extends Stage {
        // *** Data ***
        nameCallback: NameCallback | null = null;
        passwordCallback: PasswordCallback | null = null;
        stringAttributeInputCallbacks: AttributeInputCallback<string>[] | null = null;

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange() {
            this.init();
        }

        // *** Lifecycle Methods ***
        created() {
            this.init();
        }

        // *** Methods ***
        init() {
            this.nameCallback = this.$util.am.getCallbackOfType<NameCallback>(this.callbacks, CallbackType.NameCallback);
            this.passwordCallback = this.$util.am.getCallbackOfType<PasswordCallback>(this.callbacks, CallbackType.PasswordCallback);
            this.stringAttributeInputCallbacks = this.$util.am.getCallbacksOfType<AttributeInputCallback<string>>(this.callbacks, CallbackType.StringAttributeInputCallback);
        }
    };
