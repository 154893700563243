
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: {
            GenericCallbackRenderer
        }
    })
    export default class EnterUserID extends Stage {
        // *** Data ***
        supportEmail = this.$root.$t('common.trivir-email-general');

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange() {
            this.init();
        }

        // *** Lifecycle Methods ***
        created() {
            this.init();
        }

        // *** Methods ***
        init() {
            // Customize callback component rendering via createElement() data object
            const nameCallback = this.$util.am.getCallbackOfType(this.callbacks, CallbackType.NameCallback);
            nameCallback.createElementData = {
                props: {
                    label: this.$t('login.stages.enter-user-id.label')
                }
            };
        }
    };
