
    import Component, { mixins } from 'vue-class-component';
    import { FRStep, CallbackType, HiddenValueCallback } from '@forgerock/javascript-sdk';
    import { Contact, ContactType, OathDevice, Passkey, PushDevice } from '@/components/profile/common/Types';
    import Profile from '@/components/profile/Profile';
    import ProfileMixin from '@/components/profile/common/ProfileMixin';
    import SecurityMethodSetupSheet from '@/components/profile/mfa/SecurityMethodSetupSheet.vue';
    import ContactInfoSheet from '@/components/profile/common/ContactInfoSheet.vue';
    import AddContactSecurityMethodDialog from '@/components/profile/mfa/dialog/AddContactSecurityMethodDialog.vue';
    import AddTotpDialog from '@/components/profile/mfa/dialog/AddTotpDialog.vue';
    import AddWebAuthnDialog from '@/components/profile/mfa/dialog/AddWebAuthnDialog.vue';

    @Component({
        components: {
            AddContactSecurityMethodDialog,
            AddTotpDialog,
            AddWebAuthnDialog,
            ContactInfoSheet,
            SecurityMethodSetupSheet
        }
    })
    export default class Mfa extends mixins(Profile, ProfileMixin) {
        // *** Data ***
        ContactType = ContactType; // Make available to html template

        contacts: Contact[] = [];
        oathDevices: OathDevice[] = [];
        passkeys: Passkey[] = [];
        // pushDevices: PushDevice[] = [];

        code = '';
        isEmployee = false;
        submitInProgress = false;

        showAddOathDialog = false;
        // showAddPushDialog = false;
        showAddWebAuthnDialog = false;
        showRenameWebAuthnDialog = false;
        showAddContactSecurityMethodDialog = false;
        addContactSecurityMethodType: ContactType | null = null;

        // *** Computed Properties ***
        get isSetupRequired(): boolean {
            return !this.userHasSecurityMethodItem;
        }

        get securityMethodContacts(): Array<Record<string, any>> {
            return this.smsSecurityMethodContacts.concat(this.emailSecurityMethodContacts);
        }

        get smsSecurityMethodContacts(): Contact[] {
            return this.contacts.filter(contact => {
                return contact.otp && contact.type === 'sms';
            });
        }

        get emailSecurityMethodContacts(): Contact[] {
            return this.contacts.filter(contact => {
                return contact.otp && contact.type === 'email';
            });
        }

        get userHasSecurityMethodAvailable(): boolean {
            return !this.userHasSmsSecurityMethodItem || !this.userHasEmailSecurityMethodItem || this.userHasOathDevice || !this.userHasPasskey;
        }

        get userHasSecurityMethodItem(): boolean {
            return this.userHasSmsSecurityMethodItem || this.userHasEmailSecurityMethodItem || this.userHasOathDevice || this.userHasPasskey;
        }

        get userHasSmsSecurityMethodItem(): boolean {
            return this.smsSecurityMethodContacts.length > 0;
        }

        get userHasEmailSecurityMethodItem(): boolean {
            return this.emailSecurityMethodContacts.length > 0;
        }

        get userHasOathDevice(): boolean {
            return this.oathDevices.length > 0;
        }

        get userHasPasskey(): boolean {
            return this.passkeys.length > 0;
        }

        // get userHasPushDevice(): boolean {
        //     return this.pushDevices.length > 0;
        // }

        get userSecurityMethodItemCount(): number {
            return this.smsSecurityMethodContacts.length +
                this.emailSecurityMethodContacts.length +
                this.oathDevices.length +
                this.passkeys.length;
            // this.passkeys.length +
            // this.pushDevices.length;
        }

        // *** Lifecycle Methods ***
        created() {
            this.init();
        }

        // *** Methods ***
        async init() {
            await this.readContacts();
        }

        openAddSmsSecurityMethodDialog() {
            this.addContactSecurityMethodType = ContactType.Sms;
            this.showAddContactSecurityMethodDialog = true;
        }

        openAddEmailSecurityMethodDialog() {
            this.addContactSecurityMethodType = ContactType.Email;
            this.showAddContactSecurityMethodDialog = true;
        }

        openAddOathSecurityMethodDialog() {
            this.showAddOathDialog = true;
        }

        openAddPasskeySecurityMethodDialog() {
            this.showAddWebAuthnDialog = true;
        }

        // openAddPushSecurityMethodDialog() {
        //     this.showAddPushDialog = true;
        // }

        async readContacts(): Promise<void> {
            try {
                const response = await this.readContactsReq() as FRStep;

                if (response.callbacks) {
                    const hiddenValueCallbacks = this.$util.am.getCallbacksOfType<HiddenValueCallback>(response.callbacks, CallbackType.HiddenValueCallback);

                    // Get isEmployee
                    this.isEmployee = !!hiddenValueCallbacks.find(callback => callback.getInputValue() === 'isEmployee');

                    // Get OTP contacts
                    const contactsCallback = hiddenValueCallbacks.find(callback => callback.getInputValue() === 'otp');
                    if (contactsCallback) {
                        this.contacts = this.$util.formatOtpContacts(JSON.parse(contactsCallback.getOutputValue() as string));
                    }

                    // Get OATH devices
                    const oathCallback = hiddenValueCallbacks.find(callback => callback.getInputValue() === 'oath');
                    if (oathCallback) {
                        const oathDevice = JSON.parse(oathCallback.getOutputValue() as string);
                        this.oathDevices = [{
                            id: 'Only one authenticator app at a time is allowed.',
                            type: ContactType.Oath,
                            uuid: oathDevice.uuid
                        }];
                    }

                    // // Get push devices
                    // const pushCallback = hiddenValueCallbacks.find(callback => callback.getInputValue() === 'push');
                    // if (pushCallback) {
                    //     const pushDevice = JSON.parse(pushCallback.getOutputValue() as string);
                    //     this.pushDevices = [{
                    //         id: pushDevice.deviceName,
                    //         type: ContactType.Push,
                    //         uuid: pushDevice.uuid
                    //     }];
                    // }

                    // Get passkeys
                    const passkeyCallback = hiddenValueCallbacks.find(callback => callback.getInputValue() === 'webauthn');
                    if (passkeyCallback) {
                        const passkey = JSON.parse(passkeyCallback.getOutputValue() as string);
                        this.passkeys = [{
                            id: passkey.deviceName,
                            type: ContactType.Passkey,
                            uuid: passkey.uuid
                        }];
                    }
                }
            } catch (error) {
                this.$notification.error({
                    title: 'Initialization Error',
                    message: 'Unable to retrieve list of configured security methods.'
                }, error);
            }
        }

        async createContact(contact: Contact): Promise<void> {
            try {
                const response = await this.createContactReq(contact) as FRStep;

                if (response.callbacks) {
                    const hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(response.callbacks, CallbackType.HiddenValueCallback);
                    if (hiddenValueCallback.getOutputValue() === 'true') {
                        await this.readContacts();
                    }
                }
            } catch (error) {
                this.$notification.error({
                    title: 'Contact Error',
                    message: 'Unable to add contact.'
                }, error);
            }
        }

        async removeSecurityMethod(contact: Contact) {
            const response = await this.removeSecurityMethodReq(contact) as FRStep;

            if (response.callbacks) {
                const hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(response.callbacks, CallbackType.HiddenValueCallback);
                if (hiddenValueCallback.getOutputValue() === 'true') {
                    switch (contact.type) {
                        case ContactType.Email:
                        case ContactType.Sms:
                            this.contacts = this.contacts.filter(c => c.id !== contact.id);
                            break;
                        case ContactType.Oath:
                            this.oathDevices = [];
                            break;
                        case ContactType.Passkey:
                            this.passkeys = [];
                            break;
                        // case ContactType.Push:
                        //     this.pushDevices = [];
                        //     break;
                    }
                }
            }
        }
    }
