
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop, PropSync, Watch } from 'vue-property-decorator';

    @Component
    export default class NewAndConfirmPasswordFields extends Vue {
        @PropSync('password', { type: String, required: true })
        readonly syncedPassword!: string;

        @PropSync('confirmPassword', { type: String, required: true })
        readonly syncedConfirmPassword!: string;

        @Prop({ type: Boolean, default: false })
        readonly autofocus!: string;

        @Prop({ type: String, required: false })
        readonly newPasswordLabel!: string;

        // *** Data ***
        newPasswordHasFocus = false;
        newPasswordVisited = false;
        showPasswordRequirements = false;

        // *** Computed Properties ***
        get newPasswordInputId(): string {
            return 'new-password';
        }

        get allPasswordRequirementsMet(): boolean {
            return this.passwordMeetsLengthRequirement &&
                this.passwordMeetsLetterRequirement &&
                this.passwordMeetsNumberRequirement &&
                this.passwordMeetsCharacterRequirements;
        }

        get passwordMeetsLengthRequirement(): boolean {
            const length = this.syncedPassword.length;
            return length >= this.$util.constants.PASSWORD_MIN_LENGTH && length <= this.$util.constants.PASSWORD_MAX_LENGTH;
        }

        get passwordMeetsLetterRequirement(): boolean {
            return this.$util.constants.PASSWORD_ONE_LETTER_REGEX.test(this.syncedPassword);
        }

        get passwordMeetsNumberRequirement(): boolean {
            return this.$util.constants.PASSWORD_ONE_NUMBER_REGEX.test(this.syncedPassword);
        }

        get passwordMeetsCharacterRequirements(): boolean {
            return this.$util.constants.PASSWORD_ALLOWED_CHARACTERS_REGEX.test(this.syncedPassword);
        }

        // *** Watch Methods ***
        @Watch('syncedPassword')
        onSyncedPasswordChange() {
            // We need the password matching validation rule for the 'confirmPassword' input to re-evaluate every
            // time the 'password' input changes.
            if ((this.$refs.confirmPassword as any).isDirty) {
                this.$nextTick(() => {
                    (this.$refs.confirmPassword as any).validate();
                });
            }

            if (this.allPasswordRequirementsMet) {
                setTimeout(() => {
                    this.showPasswordRequirements = false;
                }, 600);
            } else if (this.newPasswordHasFocus) {
                this.showPasswordRequirements = true;
            }
        }

        // *** Methods ***
        focus() {
            if (this.autofocus) {
                (this.$refs.password as any).focus();
            }
        }

        newPasswordFocus() {
            this.showPasswordRequirements = !this.allPasswordRequirementsMet;
            this.newPasswordHasFocus = true;
        }

        newPasswordBlur() {
            this.newPasswordHasFocus = false;
            this.newPasswordVisited = true;
            this.showPasswordRequirements = false;
        }
    }
