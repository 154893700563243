
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { ModelSync } from 'vue-property-decorator';
    import { CallbackType, FRAuth, FRCallback, FRStep, FRWebAuthn, TextOutputCallback } from '@forgerock/javascript-sdk';
    import { StepOptions } from '@forgerock/javascript-sdk/src/auth/interfaces';
    import VCardTitleWithClose from '@/components/profile/common/dialog/VCardTitleWithClose.vue';

    enum Step {
        Registration,
        RegistrationSuccessful,
        BackupCodes
    }

    @Component({
        components: {
            VCardTitleWithClose
        }
    })
    export default class AddWebAuthnDialog extends Vue {
        @ModelSync('showDialog', 'show-dialog-change', { type: Boolean })
        syncedShowDialog!: boolean;

        // *** Data ***
        Step = Step; // Make enum available in html template
        callbacks: FRCallback[] = [];
        backupCodes: string[] = [];

        step: Step = Step.Registration;

        // *** Lifecycle Methods ***
        async created() {
            await this.registerWebAuthn();
        }

        // *** Methods ***
        closeDialog() {
            this.syncedShowDialog = false;
        }

        async registerWebAuthn() {
            const options: StepOptions = { tree: 'WebAuthnRegistration' };
            const initialResponse = await FRAuth.next(undefined, options);
            const initialStep = initialResponse as FRStep;

            try {
                const registrationStep = await FRWebAuthn.register(initialStep);
                const successResponse = await FRAuth.next(registrationStep, options) as FRStep;
                this.$emit('add');
                this.step = Step.RegistrationSuccessful;

                // Get recovery codes
                const textOutputCallback = this.$util.am.getCallbackOfType<TextOutputCallback>(successResponse.callbacks, CallbackType.TextOutputCallback);
                this.formatRecoveryCodes(textOutputCallback);
            } catch (error) {
                this.closeDialog();
                this.$notification.error({
                    title: this.$t('errors.security-method-add.title') as string,
                    message: this.$t('errors.security-method-add.message') as string
                }, error);
            }
        }

        // The recovery codes get sent in an HTML string, so we need to extract them
        formatRecoveryCodes(textOutputCallback: TextOutputCallback) {
            const callback = textOutputCallback.getOutputValue() as string;
            // Recovery codes are 10 characters in length and consist of letters and numbers. Each is appended with a \n character.
            const recoveryCodes = callback.match(/\w{10}\\n/g) || [];
            if (recoveryCodes.length) {
                this.backupCodes = recoveryCodes.map(code => code.replace('\\n', ''));
            }
        }
    }
