
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import ProfileSheet from '@/components/profile/common/ProfileSheet.vue';

    @Component({
        components: {
            ProfileSheet
        }
    })
    export default class SecurityMethodSetupSheet extends Vue {
        @Prop()
        readonly icon!: string;

        @Prop()
        readonly title!: string;

        @Prop()
        readonly description!: string;

        @Prop()
        readonly submitInProgress!: boolean;
    }
