
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, PollingWaitCallback as SDKPollingWaitCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    @Component
    export default class PollingWaitCallback extends Callback {
        @Prop({ type: Object as () => SDKPollingWaitCallback, required: true })
        readonly callback!: SDKPollingWaitCallback;

        // *** Computed Properties ***
        get message(): string {
            return this.callback.getMessage() as string;
        }

        get waitTime(): number {
            return this.callback.getWaitTime() as number;
        }

        // *** Lifecycle Methods ***
        created() {
            setInterval(() => {
                this.$emit('next');
            }, Number(this.waitTime));
        }

        // *** Methods ***
        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.PollingWaitCallback;
        }
    };
