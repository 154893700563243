
    import Vue from 'vue';

    export default Vue.extend({
        name: 'AppFooter',

        data: function() {
            return {
                currentYear: new Date().getFullYear(),
                telephone: this.$t('common.trivir-phone-number-general'),
                email: this.$t('common.trivir-email-general')
            };
        }
    });
