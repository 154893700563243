
    import Component from 'vue-class-component';
    import Stage from '@/components/auth/stage/Stage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: {
            GenericCallbackRenderer
        }
    })
    export default class EStatementsComplete extends Stage {
        // *** Methods ***
        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
