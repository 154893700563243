
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { SessionManager } from '@forgerock/javascript-sdk';

    @Component
    export default class Logout extends Vue {
        // *** Lifecycle Methods ***
        async created() {
            if ((this.$root as any).isAuthenticated) {
                await this.logout();
            }

            this.$router.push({ name: 'Login' });
        }

        // *** Methods ***
        async logout() {
            try {
                await SessionManager.logout();
                (this.$root as any).isAuthenticated = false;
                this.$store.state.showTree = false;
            } catch (error) {
                // NOTE: SessionManager.logout() swallows errors for 401 responses so there is no need for us to handle
                // that specific scenario here (in cases where we think the user has a current session but they don't
                // and the SessionManager receives 401 on the logout REST call), so this error notification is just
                // here to handle non-401 repsonses and other errors that may occur.
                this.$notification.error({
                    title: this.$t('errors.logout.title') as string,
                    message: this.$t('errors.logout.message') as string
                }, error);
            }
        }
    }
