import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref } from 'vue-property-decorator';
import { FRCallback } from '@forgerock/javascript-sdk';

// @ts-ignore
@Component
export default abstract class Stage extends Vue {
    @Prop({ type: Array as () => FRCallback[], required: true })
    readonly callbacks!: FRCallback[];

    @Prop({ type: Boolean, required: true })
    readonly submitInProgress!: boolean;

    // We can't use a type of VForm here because of:
    // 1) https://github.com/vuetifyjs/vuetify/issues/5962
    // 2) https://github.com/vuetifyjs/vuetify/issues/13043
    @Ref()
    readonly form: any;

    // *** Data ***
    valid = true;

    // *** Lifecycle Methods ***
    mounted() {
        window.scrollTo(0, 0);
    }

    // *** Methods ***
    next(skipValidationCheck = false) {
        // Type check added to avoid a programming error in stage components. Without it, if somebody accidentally puts
        // something like the following in a stage component's template, this function will not operate as intended:
        //      <v-btn @click="next" ...>
        // If 'next' does not have parentheses as shown, Vue will automatically provide an event argument to this
        // method (i.e. which does not match with 'skipValidationCheck' argument that we expect). However, if the
        // following syntax is used, Vue will NOT automatically provide an event argument:
        //      <v-btn @click="next()" ...>
        // This type check fix allows either of the two styles above to be used without affecting the behavior of this
        // method.
        if (typeof skipValidationCheck !== 'boolean') {
            skipValidationCheck = false;
        }

        if (this.valid || skipValidationCheck) {
            if (!this.submitInProgress) {
                this.$emit('next');
            }
        } else {
            if (this.form) {
                this.form.validate();
            }
        }
    }
}
