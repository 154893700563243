
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: {
            GenericCallbackRenderer
        }
    })
    export default class Default extends Stage {
        // *** Data ***
        genericCallbackRendererComponentKey = 0;

        // *** Computed Properties ***
        get showDefaultSubmitButton(): boolean {
            const confirmationCallbacks = this.$util.am.getCallbacksOfType(this.callbacks, CallbackType.ConfirmationCallback);
            return confirmationCallbacks.length === 0;
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange() {
            // Force the generic callback renderer component to reload (helps reset things like form validation, etc)
            this.genericCallbackRendererComponentKey += 1;
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    };
