
    import Component, { mixins } from 'vue-class-component';
    import { ModelSync, Prop, Watch } from 'vue-property-decorator';
    import { AxiosResponse } from 'axios';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import ProfileMixin from '@/components/profile/common/ProfileMixin';
    import VCardTitleWithClose from '@/components/profile/common/dialog/VCardTitleWithClose.vue';
    import OtpVerificationCard from '@/components/profile/common/dialog/OtpVerificationCard.vue';
    import { StepOptions } from '@forgerock/javascript-sdk/src/auth/interfaces';
    import { CallbackType, FRAuth, FRStep, HiddenValueCallback } from '@forgerock/javascript-sdk';

    enum Page {
        None,
        NewEmail,
        NewPhone,
        OtpSecurityMethodVerification
    }

    enum Scenario {
        Undetermined = 'Undetermined',
        AddExistingVerifiedContact = 'AddExistingVerifiedContact',
        AddExistingUnverifiedContact = 'AddExistingUnverifiedContact',
        AddNewContact = 'AddNewContact',
        AddNewContactImplicit = 'AddNewContactImplicit'
    }

    @Component({
        inheritAttrs: false,
        components: {
            VCardTitleWithClose,
            OtpVerificationCard
        }
    })
    export default class AddContactSecurityMethodDialog extends mixins(ProfileMixin) {
        @ModelSync('showDialog', 'show-dialog-change', { type: Boolean })
        syncedShowDialog!: boolean;

        @Prop({ validator: contactType => Object.values(ContactType).includes(contactType as ContactType), required: true })
        readonly contactType!: ContactType;

        @Prop({ type: Boolean, required: true })
        readonly mfaEnabled!: boolean;

        @Prop({ type: Array, required: true })
        readonly contacts!: Contact[];

        // *** Data ***
        Page = Page; // Make the Page enum available to the html template

        codeInvalid = false;
        currentPage: Page = Page.None;
        newEmailAddress = '';
        newPhoneNumber = '';
        scenario: Scenario = Scenario.Undetermined;
        selectedContactRadioValue = '';
        selectedContact: Contact = this.getNewContact();
        submitInProgress = false;
        valid = true;

        // *** Computed Properties ***
        get validation(): Record<string, any> {
            return {
                emailRules: [
                    (v: string) => this.isUniqueContact(v) || 'Contact already exists on your account'
                ],
                phoneRules: [
                    (v: string) => this.isUniqueContact(v) || 'Contact already exists on your account'
                ],
                securityMethodRadioRules: [
                    (v: any) => !!v || this.$root.$t('validation.required-selection')
                ]
            };
        }

        get newContactPage(): Page {
            if (this.contactType === ContactType.Sms) {
                return Page.NewPhone;
            } else {
                return Page.NewEmail;
            }
        }

        get securityMethodContacts(): Contact[] {
            return this.contacts.filter(contact => {
                return contact.otp;
            });
        }

        get hasSecurityMethods(): boolean {
            return this.securityMethodContacts.length > 0;
        }

        get availableSecurityMethodContacts(): Contact[] {
            return this.contacts.filter(contact => {
                return !contact.otp && contact.type === this.contactType;
            });
        }

        get hasAvailableSecurityMethodContacts(): boolean {
            return this.availableSecurityMethodContacts.length > 0;
        }

        get isAddExistingVerifiedContactScenario(): boolean {
            return this.scenario === Scenario.AddExistingVerifiedContact;
        }

        get isAddExistingUnverifiedContactScenario(): boolean {
            return this.scenario === Scenario.AddExistingUnverifiedContact;
        }

        get isAddNewContactScenario(): boolean {
            return this.scenario === Scenario.AddNewContact;
        }

        get isAddNewContactImplicitScenario(): boolean {
            return this.scenario === Scenario.AddNewContactImplicit;
        }

        get isMfaActivationRequired(): boolean {
            return !this.mfaEnabled && !this.hasSecurityMethods;
        }

        // *** Watch Methods ***
        @Watch('selectedContactRadioValue')
        onSelectedContactRadioValueChange(newValue: string) {
            const contact = this.contacts.find(contact => contact.id === newValue);
            if (newValue === 'new') {
                this.scenario = Scenario.AddNewContact;
                this.selectedContact = this.getNewContact();
            } else if (contact) {
                if (contact.verifiedOn === null) {
                    this.scenario = Scenario.AddExistingUnverifiedContact;
                } else {
                    this.scenario = Scenario.AddExistingVerifiedContact;
                }

                this.selectedContact = contact;
            }
        }

        @Watch('newEmailAddress')
        onNewEmailAddressChange(newValue: string) {
            this.selectedContact.id = newValue.toLowerCase();
        }

        @Watch('newPhoneNumber')
        onNewPhoneNumberChange(newValue: string) {
            this.selectedContact.id = this.$util.getUnformattedPhone(newValue);
        }

        // *** Lifecycle Methods ***
        created() {
            this.scenario = Scenario.AddNewContactImplicit;
            this.proceedToNewContactEntry();
        }

        // *** Methods ***
        async checkContact() {
            try {
                const res = await fetch(`${process.env.VUE_APP_HOME_LOAN_URL}/api/risk/${this.newEmailAddress}`);
                const data = await res.json();

                if (data.outcomes.includes('ALLOW')) {
                    const options: StepOptions = { tree: 'Send OTP', query: { email: this.newEmailAddress } };
                    const response = await FRAuth.next(undefined, options) as FRStep;

                    if (response.callbacks) {
                        const hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(response.callbacks, CallbackType.HiddenValueCallback);
                        if (hiddenValueCallback.getOutputValue() === 'true') {
                            await this.proceedToVerification();
                        }
                    }
                } else {
                    this.$notification.error({
                        title: 'Invalid',
                        message: data.reasons[0].split('[DENY]')[1].trim() as string
                    });
                }
            } catch (error) {
                this.$notification.error({
                    title: 'Contact Error',
                    message: 'Unable to add contact.'
                }, error);
            }
        }

        isUniqueContact(contactId: string): boolean {
            let unformattedContactId = contactId;
            if (this.contactType === ContactType.Sms) {
                unformattedContactId = this.$util.getUnformattedPhone(contactId);
            }
            return !this.contacts.find(contact => contact.id === unformattedContactId);
        }

        cancel() {
            this.$emit('cancel');
        }

        proceedToNewContactEntry() {
            this.currentPage = this.newContactPage;
        }

        backFromVerification() {
            this.proceedToNewContactEntry();
        }

        async proceedToVerification() {
            if (!this.valid || this.submitInProgress) {
                (this.$refs.form as any).validate();
                return;
            }

            this.codeInvalid = false;

            try {
                this.currentPage = Page.OtpSecurityMethodVerification;
            } catch (error) {
                // Do nothing - don't transition to next page on error
            }
        }

        async initiateMfaActivation(): Promise<AxiosResponse<any>> {
            return this.activateMfaReq(this.selectedContact);
        }

        createContact(): void {
            this.$emit('add', this.selectedContact);
        }

        verifyCode(code: string) {
            this.createContact();
            this.syncedShowDialog = false;
        }

        getNewContact(): Contact {
            return {
                id: null,
                type: this.contactType.toString(),
                otp: true,
                alert: false
            };
        }
    }
