import Vue from 'vue';
import Component from 'vue-class-component';
import { AxiosResponse } from 'axios';
import { Contact, ContactType } from '@/components/profile/common/Types';
import { FRAuth, FRStep, FRLoginFailure, FRLoginSuccess } from '@forgerock/javascript-sdk';
import { StepOptions } from '@forgerock/javascript-sdk/src/auth/interfaces';

@Component
export default class ProfileMixin extends Vue {
    // *** Data ***
    commonAxiosConfig: Record<string, any> = {
        headers: {
            'Accept-API-Version': 'resource=1.0, protocol=1.0'
        },
        withCredentials: true
    };

    readonly realmUrlPath = this.$util.am.getRealmUrlPathFromRealmPath((this.$root as any).config.amRealmPath);

    verificationToken = '';

    // *** Methods ***
    // Common Methods
    isInvalidCodeError(error: any) {
        return error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.code === 400 &&
            error.response.data.message === 'The provided code is not correct.';
    }

    // Alert Methods
    async toggleAlertReq(contact: Contact): Promise<FRStep | FRLoginSuccess | FRLoginFailure> {
        const query = {
            requestMethod: 'POST',
            contactType: contact.type as string,
            id: contact.id as string
        };

        const options: StepOptions = { tree: 'ManageAlerts', query };
        return FRAuth.next(undefined, options);
    }

    // MFA Methods
    async activateMfaReq(contact: Contact): Promise<AxiosResponse<any>> {
        const responsePromise = this.$axios.post(`${this.realmUrlPath}/afcu/user/me/mfa?_action=activate`, contact, this.commonAxiosConfig);
        try {
            const response = await responsePromise;
            this.verificationToken = response.data.token;
        } catch (error) {
            this.$notification.error({
                title: '2-Step Verification Error',
                message: 'Unable to initiate activation of 2-step verification.'
            }, error);
        }

        return responsePromise;
    }

    async removeSecurityMethodReq(contact: Contact): Promise<FRStep | FRLoginSuccess | FRLoginFailure> {
        const query = {
            requestMethod: 'DELETE',
            contactType: contact.type as string,
            id: ''
        };

        if (contact.type === ContactType.Email || contact.type === ContactType.Sms) {
            query.id = contact.id as string;
        }

        const options: StepOptions = { tree: 'Manage MFA', query };
        return FRAuth.next(undefined, options);
    }

    // Contact Methods
    async readContactsReq(): Promise<FRStep | FRLoginSuccess | FRLoginFailure> {
        const options: StepOptions = { tree: 'Manage MFA', query: { requestMethod: 'GET' } };
        return FRAuth.next(undefined, options);
    }

    async createContactReq(contact: Contact): Promise<FRStep | FRLoginSuccess | FRLoginFailure> {
        const query = {
            requestMethod: 'POST',
            contactType: contact.type as string,
            id: contact.id as string,
            alert: contact.alert.toString(),
            otp: contact.otp.toString()
        };

        const options: StepOptions = { tree: 'Manage MFA', query };
        return FRAuth.next(undefined, options);
    }

    async updateContactReq(contact: Contact): Promise<AxiosResponse<any>> {
        const urlEncodedContactId = encodeURIComponent(contact.id as string); // Needed for email addresses with special characters
        return this.$axios.put(`${this.realmUrlPath}/afcu/user/me/contact/${urlEncodedContactId}`, contact, this.commonAxiosConfig);
    }
}
