
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback, PollingWaitCallback as SDKPollingWaitCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';
    import PollingWaitCallback from '@/components/auth/callback/PollingWaitCallback.vue';

    @Component({
        components: { ConfirmationCallback, PollingWaitCallback }
    })
    export default class PushPullingWait extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;
        pollingWaitCallback: SDKPollingWaitCallback | null = null;

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
                this.pollingWaitCallback = this.$util.am.getCallbackOfType<SDKPollingWaitCallback>(callbacks, CallbackType.PollingWaitCallback);
            } catch (error) {
                console.error(error); // TODO - Handle error
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
