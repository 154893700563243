
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, PasswordCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import NewAndConfirmPasswordFields from '@/components/common/NewAndConfirmPasswordFields.vue';

    @Component({
        components: {
            NewAndConfirmPasswordFields
        }
    })
    export default class PasswordExpired extends Stage {
        // *** Data ***
        passwordCallback: PasswordCallback | null = null;
        confirmPasswordCallback: PasswordCallback | null = null;
        textOutputCallback: TextOutputCallback | null = null;

        valid = true;

        // *** Computed Properties ***
        get password(): string {
            return this.passwordCallback ? this.passwordCallback.getInputValue() as string : '';
        }

        set password(password: string) {
            if (this.passwordCallback) {
                this.passwordCallback.setInputValue(password);
            }
        }

        get confirmPassword(): string {
            return this.confirmPasswordCallback ? this.confirmPasswordCallback.getInputValue() as string : '';
        }

        set confirmPassword(password: string) {
            if (this.confirmPasswordCallback) {
                this.confirmPasswordCallback.setInputValue(password);
            }
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
            this.form.resetValidation();
            (this.$refs.newAndConfirmPasswordFields as any).focus();
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data
            this.passwordCallback = null;
            this.confirmPasswordCallback = null;
            this.textOutputCallback = null;

            // Re-init Data
            const passwordCallbacks: PasswordCallback[] = this.$util.am.getCallbacksOfType(callbacks, CallbackType.PasswordCallback);
            for (const passwordCallback of passwordCallbacks) {
                const prompt = passwordCallback.getPrompt();
                if (prompt === 'newPassword') {
                    this.passwordCallback = passwordCallback;
                } else if (prompt === 'confirmPassword') {
                    this.confirmPasswordCallback = passwordCallback;
                }
            }

            try {
                this.textOutputCallback = this.$util.am.getCallbackOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);
                if (this.textOutputCallback) {
                    this.$notification.error({
                        title: this.$t('errors.password-change.title') as string,
                        message: this.textOutputCallback.getOutputValue() as string
                    });
                }
            } catch (error) {
                // Do nothing. It is not considered an error condition if the text output callback is missing since it
                // is only sent when there is an error condition.
            }
        }
    }
