
    export default {
        name: 'LandingPage',

        data() {
            return {
                options: [
                    { title: 'Checking & Savings', icon: 'savings', link: process.env.VUE_APP_ONLINE_BANKING_URL },
                    { title: 'Home Loan', icon: 'cottage', link: process.env.VUE_APP_HOME_LOAN_URL },
                    { title: 'Financial Education', icon: 'school', link: process.env.VUE_APP_EDUCATION_URL }
                ]
            };
        },

        methods: {
            onOptionClick(link: string) {
                window.location.href = link;
            }
        }
    };
