
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';

    enum Mode {
        Current = 'current',
        New = 'new',
        Confirm = 'confirm'
    }

    @Component({
        inheritAttrs: false
    })
    export default class VTextFieldPassword extends Vue {
        @Prop({ type: String, required: true })
        readonly value!: string;

        @Prop({ type: String, required: false })
        readonly label!: string;

        @Prop({ type: String, required: false })
        readonly mode!: string;

        @Prop({ type: Boolean, required: false })
        readonly newPasswordRequirementsMet!: boolean;

        @Prop({ type: String, default: '' })
        readonly passwordToMatch!: string; // Only used when mode is 'Mode.Confirm'

        // *** Data ***
        Mode = Mode; // Make enum available in html template

        showPassword = false;

        // *** Computed Properties ***
        get autocomplete(): string | undefined {
            if (this.mode) {
                return this.mode === Mode.Current ? 'current-password' : 'new-password';
            } else {
                // Meant for generic callback rendering during auth where we can't make any assumptions about what the
                // autocomplete value should be so we don't set a value;
                return undefined;
            }
        }

        get defaultInputLabel(): string {
            let inputLabel;
            switch (this.mode) {
                case Mode.New:
                    inputLabel = this.$t('common.passwords.new-label') as string;
                    break;
                case Mode.Confirm:
                    inputLabel = this.$t('common.passwords.confirm-label') as string;
                    break;
                case Mode.Current:
                default:
                    inputLabel = this.$t('common.password') as string;
            }
            return inputLabel;
        }

        get inputLabel(): string {
            return this.label ? this.label : this.defaultInputLabel;
        }

        get isDirty(): boolean {
            return (this.$refs.password as any).isDirty;
        }

        get rules(): any[] {
            let passwordRules: any[];

            switch (this.mode) {
                case Mode.New:
                    passwordRules = [
                        (v: string) => !!v ||
                            this.$t('validation.required', { label: this.$t('common.password') }),
                        () => this.newPasswordRequirementsMet ||
                            this.$t('validation.password-requirements-not-met')
                    ];
                    break;
                case Mode.Confirm:
                    passwordRules = [
                        (v: string) => !!v ||
                            this.$t('validation.required', { label: this.$t('common.password') }),
                        () => this.value === this.passwordToMatch ||
                            this.$t('validation.password-match')
                    ];
                    break;
                case Mode.Current:
                default:
                    passwordRules = [
                        (v: string) => !!v ||
                            this.$t('validation.required', { label: this.$t('common.password') })
                    ];
            }

            return passwordRules;
        }

        // *** Methods ***
        focus() {
            (this.$refs.password as any).focus();
        }

        validate() {
            (this.$refs.password as any).validate();
        }
    }
