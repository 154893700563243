import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('../../shared/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

// NOTE: VueI18n provides $t(), $tc(), among other methods on all Vue components in this project.
// This exported VueI18n instance below is setup in main.ts.
//
// In components that utilize the Vue Class Component syntax, however, there is some sort of incompatibility
// with the way class components are initialized and this.$t() cannot be referenced when initializing class
// variables (it compiles but running the SPA results in '_t() undefined' errors). So in the case of class
// components we call this.$root.$t() instead of this.$t() for class/data variables since this.$t() results in errors.
// Also note that if a class variable references a class method or computed variable to initialize it's value, any
// references to this.$t() in the class method or computed variable must be changed to this.$root.$t() as well. An
// alternative workaround is to import the VueI18n instance directly from '@/i18n' and call i18n.t() instead of
// this.$t() for the problem scenarios.
export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
});
