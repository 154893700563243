
    import Component from 'vue-class-component';
    import ChangeChallengeQuestions from '@/components/profile/kba/ChangeChallengeQuestions.vue';
    import ChangePassword from '@/components/profile/password/ChangePassword.vue';
    import ContactManagement from '@/components/profile/manage/ContactManagement.vue';
    import Mfa from '@/components/profile/mfa/Mfa.vue';
    import Profile from '@/components/profile/Profile';
    import SettingsOption from '@/components/profile/SettingsOption.vue';

    enum Page {
        ChangeChallengeQuestions,
        ChangePassword,
        ContactManagement,
        Mfa,
        ProfileSettings
    }

    @Component({
        components: {
            Mfa,
            ChangePassword,
            ChangeChallengeQuestions,
            ContactManagement,
            SettingsOption
        }
    })
    export default class ProfileSettings extends Profile {
        // *** Data ***
        Page = Page; // Make the Page enum available to the html template
        currentPage: Page = Page.ProfileSettings;
    };
