
    import Component from 'vue-class-component';
    import Stage from '@/components/auth/stage/Stage';
    import { CallbackType, ConfirmationCallback as SDKConfirmationCallback, FRCallback } from '@forgerock/javascript-sdk';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class DemographicInfo extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;
        formAges: string[] = ['Under 18', '18-24', '25-34', '35-44', '45-54', '55-64', 'Above 64', 'I do not wish to provide this information'];
        formEthnicities: string[] = ['Hispanic or Latino', 'Not Hispanic or Latino', 'I do not wish to provide this information'];
        formRaces: string[] = ['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Native Hawaiian or Other Pacific Islander', 'White', 'I do not wish to provide this information'];
        formGender: string[] = ['Female', 'Male', 'Other', 'I do not wish to provide this information'];
        page = 0;
        radioAge = '';
        radioEthnicity = '';
        radioGender = '';

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data

            // Re-init Data
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
            } catch (error) {
                console.error(error);
            }
        }

        submitConfirmation(skipValidation: boolean) {
            if (skipValidation) {
                this.next(true); // User clicks 'Submit'
            } else {
                this.$router.push({ name: 'LandingPage' }); // User clicks cancel
            }
        }
    }
