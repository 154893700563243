
    import Component from 'vue-class-component';
    import Profile from '@/components/profile/Profile';

    @Component
    export default class ChangeUsername extends Profile {
        // *** Data ***
        valid = true;

        // *** Methods ***
        async save() {
            if (this.valid) {
                this.submitInProgress = true;

                const data = {};

                const config = {
                    headers: {
                        'Accept-API-Version': 'resource=2.0'
                    },
                    withCredentials: true
                };

                try {
                    // TODO - Replace with actual URL
                    await this.$axios.post('/users/todo', data, config);
                    this.proceedToDestination();
                } catch (error) {
                    this.$notification.error({
                        title: 'Username Change Error',
                        message: 'Error encountered while saving username.'
                    }, error);
                } finally {
                    this.submitInProgress = false;
                }
            }
        }
    }
