import { render, staticRenderFns } from "./KbaSetup.vue?vue&type=template&id=502e3bec&scoped=true&"
import script from "./KbaSetup.vue?vue&type=script&lang=ts&"
export * from "./KbaSetup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502e3bec",
  null
  
)

export default component.exports