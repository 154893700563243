
    import Component from 'vue-class-component';
    import Stage from './Stage';
    import ContentWrapper from '@/components/base/ContentWrapper.vue';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: { ContentWrapper, GenericCallbackRenderer }
    })
    export default class Geofencing extends Stage {}
