import { render, staticRenderFns } from "./CustomEnterUserDetails.vue?vue&type=template&id=48cd9de8&scoped=true&"
import script from "./CustomEnterUserDetails.vue?vue&type=script&lang=ts&"
export * from "./CustomEnterUserDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cd9de8",
  null
  
)

export default component.exports