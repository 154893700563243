
    import Component from 'vue-class-component';
    import Stage from '@/components/user-self-service/stage/Stage';

    @Component
    export default class ActivityAudit extends Stage {
        // *** Computed Properties ***
        get title(): string {
            return this.ussResponseData.status.success
                ? this.$t('register.activity-audit.title-success') as string
                : this.$t('register.activity-audit.title-error') as string;
        }

        // *** Methods ***
        getInputData(): Record<string, any> {
            return {};
        }
    }
