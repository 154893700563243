
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';

    @Component
    export default class PushRecoveryCodes extends Stage {
        // *** Data ***
        textOutputCallback: TextOutputCallback | null = null;

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        mounted() {
            if (this.textOutputCallback) {
                // eslint-disable-next-line no-new-func
                Function(this.textOutputCallback.payload.output[0].value as string)();

                const wrapper = this.$refs.wrapper as HTMLElement;
                const html = wrapper.innerHTML;
                wrapper.innerHTML = '';

                const container = this.$refs.container as HTMLElement;
                container.innerHTML = html;
            }
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.textOutputCallback = this.$util.am.getCallbackOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);
            } catch (error) {
                console.error(error); // TODO - Handle error
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }

    // This is the script AM sends down in the TextOutptCallback
    // script() {
    //     const newLocation = document.getElementById('wrapper');
    //     const oldHtml = newLocation!.getElementsByTagName('fieldset')[0].innerHTML;
    //
    //     newLocation!.getElementsByTagName('fieldset')[0].innerHTML =
    //         '<div class="panel panel-default">' +
    //             '<div class="panel-body text-center">' +
    //                 '<h3>Your Recovery Codes</h3>' +
    //                 '<h4>You must make a copy of these recovery codes. They cannot be displayed again.</h4>' +
    //             ' </div>' +
    //
    //             '<div class="text-center">9giEWw2Pjp</div>' +
    //             '<div class="text-center">I6FPAYiDRB</div>' +
    //             '<div class="text-center">GfMVenV9hY</div>' +
    //             '<div class="text-center">oe6YwnvYPB</div>' +
    //             '<div class="text-center">Mo6FPf6xtZ</div>' +
    //             '<div class="text-center">YP2ME75bXl</div>' +
    //             '<div class="text-center">65vMURp2YG</div>' +
    //             '<div class="text-center">KzbrNC4q9e</div>' +
    //             '<div class="text-center">GpJZapD0qW</div>' +
    //             '<div class="text-center">qv2eQhNtxO</div>' +
    //
    //             '<div class="panel-body text-center">' +
    //                 '<p>Use one of these codes to authenticate if you lose your device, which has been named: <em>Push Device</em></p>' +
    //             '</div>' +
    //         '</div>' +
    //         oldHtml;
    //
    //     document.body.appendChild(newLocation!);
    // }
    }
