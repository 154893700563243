
    import Vue from 'vue';
    import { Notification, notificationStore } from '@/services/notification';

    const standardNotificationTypes = ['success', 'error', 'warning', 'info'];

    export default Vue.extend({
        name: 'Notifications',

        computed: {
            closeText: function(): string {
                if (this.notification && this.notification.closeText) {
                    return this.notification.closeText;
                } else {
                    // Default values if closeText not provided
                    return this.displayAsSnackbar ? this.$t('common.close') as string : this.$t('common.ok') as string;
                }
            },
            displayAsSnackbar: function(): boolean {
                return this.$vuetify.breakpoint.mdAndUp;
            },
            notifications: function(): Array<Notification> {
                return notificationStore.notifications;
            },
            notification: function(): Notification | undefined {
                if (this.notifications.length === 0) {
                    return undefined;
                }

                // All notifications will be logged but only the first notification will be shown.
                const notification = this.notifications[0];

                // Force specific color for known notification types
                if (notification.type && standardNotificationTypes.includes(notification.type)) {
                    notification.color = notification.type;
                }

                return notification;
            },
            showNotification: {
                get: function(): boolean {
                    return this.notifications.length > 0;
                },
                set: function(newValue) {
                    if (!newValue) {
                        notificationStore.clearFirst();
                    }
                }
            }
        },
        watch: {
            notification: function(newNotification: Notification | undefined, oldNotification: Notification | undefined) {
                // If newNotification is something other than undefined, it indicates the point at which the
                // notification is first shown
                if (newNotification !== undefined) {
                    // Acknowledge the notification immediately for notifications displayed as snackbar (i.e. in case of
                    // snackbar no explicit acknowledgement by the user is required)
                    if (this.displayAsSnackbar) {
                        newNotification.acknowledge();
                    }

                    if (newNotification !== oldNotification) {
                        newNotification.displayTime = Date.now();
                    }
                }
            }
        }
    });
