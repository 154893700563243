import { CallbackType, FRCallback, HiddenValueCallback, PollingWaitCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
import { Service } from '@/services/service';
import { Contact } from '@/components/profile/common/Types';

class ForgeRockAMUtilService {
    public static getCallbacksOfType<T extends FRCallback>(callbacks: FRCallback[], type: CallbackType): T[] {
        return callbacks.filter(callback => callback.getType() === type) as T[];
    }

    public static getCallbackOfType<T extends FRCallback>(callbacks: FRCallback[], type: CallbackType): T {
        const callbacksOfType = this.getCallbacksOfType<T>(callbacks, type);
        if (callbacksOfType.length !== 1) {
            throw new Error(`Expected 1 callback of type "${type}", but found ${callbacksOfType.length}`);
        }
        return callbacksOfType[0];
    }

    // Takes a realm path returned by AM's '/json/serverinfo' endpoint and converts it into an extended realm path for '/json/realm/*' endpoints
    public static getRealmUrlPathFromRealmPath(amRealmPath: string): string {
        return amRealmPath === '/' ? '/realms/root' : `/realms/root/realms${amRealmPath}`;
    }

    public static getStageNameFromCallbacks(callbacks: FRCallback []): string | undefined {
        let stageName: string | undefined;
        const hiddenValueCallbacks: HiddenValueCallback[] = this.getCallbacksOfType(callbacks, CallbackType.HiddenValueCallback);
        for (const hiddenValueCallback of hiddenValueCallbacks) {
            if (this.isStageHiddenValueCallback(hiddenValueCallback)) {
                stageName = hiddenValueCallback.getOutputValue('value') as string;
                break;
            } else if (this.isPushRegistration(hiddenValueCallback)) {
                stageName = 'PushRegistration';
                break;
            } else if (this.isTotpRegistration(hiddenValueCallback)) {
                stageName = 'TotpRegistration';
                break;
            }
        }

        // TODO - Would be nice to eventually create a method for determining all stage names
        // This condition is specific to the PushPollingWait and PushRecoveryCodes stages, because they do not send HiddenValueCallbacks
        if (!stageName) {
            const pollingWaitCallbacks: PollingWaitCallback[] = this.getCallbacksOfType(callbacks, CallbackType.PollingWaitCallback);
            for (const pollingWaitCallback of pollingWaitCallbacks) {
                if (this.isPushPollingWait(pollingWaitCallback)) {
                    stageName = 'PushPollingWait';
                    break;
                }
            }

            const textOutputCallbacks: TextOutputCallback[] = this.getCallbacksOfType(callbacks, CallbackType.TextOutputCallback);
            for (const textOutputCallback of textOutputCallbacks) {
                if (this.isPushRecoveryCodes(textOutputCallback)) {
                    stageName = 'PushRecoveryCodes';
                    break;
                }
            }
        }
        return stageName;
    }

    public static isKbaQuestionGroupsCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'questionGroups';
    }

    // TODO - Would be nice to eventually create a method for determining all stage names
    public static isPushPollingWait(callback: FRCallback): boolean {
        return callback.getOutputByName('waitTime', undefined) === '8000' && // The waitTime is set on the node in the tree, which could change
            callback.getOutputByName('message', undefined) === 'Waiting for response...';
    }

    // TODO - This is a really rough way of doing this. Right now it relies on the order of the function calls in getStageNameFromCallbacks
    public static isPushRecoveryCodes(callback: FRCallback): boolean {
        // @ts-ignore
        const message: string = callback.getOutputByName('message', undefined);

        if (message) {
            return callback.getOutputByName('messageType', undefined) === '4' &&
                message.slice(6, 15) === 'Copyright';
        }

        return false;
    }

    // TODO - Would be nice to eventually create a method for determining all stage names
    public static isPushRegistration(callback: FRCallback): boolean {
        return (callback.getOutputValue() as string).includes('pushauth');
    }

    // TODO - Would be nice to eventually create a method for determining all stage names
    public static isTotpRegistration(callback: FRCallback): boolean {
        return (callback.getOutputValue() as string).includes('otpauth');
    }

    public static isRSADeviceFingerprintCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'devReqFP';
    }

    public static isStageHiddenValueCallback(callback: FRCallback): boolean {
        return callback.getType() === CallbackType.HiddenValueCallback &&
            callback.getOutputByName('id', undefined) === 'stage' &&
            typeof callback.getOutputValue('value') === 'string';
    }
}

class UtilService extends Service {
    public static am = ForgeRockAMUtilService;

    public static constants = Object.freeze({
        PASSWORD_ALLOWED_CHARACTERS_REGEX: /^[a-zA-Z0-9!#$&"(),./:?@'-]*$/,
        PASSWORD_MIN_LENGTH: 8,
        PASSWORD_MAX_LENGTH: 32,
        PASSWORD_ONE_LETTER_REGEX: /^.*[a-zA-Z]+.*$/,
        PASSWORD_ONE_NUMBER_REGEX: /^.*[0-9]+.*$/,
        KBA_ANSWERS_REGEX: /^[a-zA-Z0-9 ]*$/
    });

    public static camelOrPascalCaseToKebabCase(str: string): string {
        // The following can't be used yet since iOS/Safari don't support regex look behind assertions
        // return str
        //     .replace(/\B(?:([A-Z])(?=[a-z]))|(?:(?<=[a-z0-9])([A-Z]))/g, '-$1$2')
        //     .toLowerCase();
        // So we're using this alternate implementation instead (until the above works in most browsers)
        return str
            .replace(/\B([A-Z])(?=[a-z])/g, '-$1')
            .replace(/\B([a-z0-9])([A-Z])/g, '$1-$2')
            .toLowerCase();
    }

    public static getFormattedPhone(unformattedPhone: string): string {
        if (unformattedPhone.length !== 10) {
            console.error('getFormattedPhone() function expects a 10-digit number');
            return unformattedPhone;
        }

        return `(${unformattedPhone.substring(0, 3)}) ${unformattedPhone.substring(3, 6)}-${unformattedPhone.substring(6, 10)}`;
    }

    public static getUnformattedPhone(formattedPhoneNumber: string): string {
        const result = formattedPhoneNumber.match(/\d/g);
        return result ? result.join('') : '';
    }

    public static localeMessageDefined(localeMessagePath: string): boolean {
        return this.$vue.$t(localeMessagePath) !== localeMessagePath;
    }

    // Positions dialog based on whether it's accessed from mobile app
    public static dialogPosition(): string {
        return (this.$vue as any).isWebview ? 'dialog-top align-self-start' : '';
    }

    public static formatOtpContacts(contacts: Record<string, any>): Contact[] {
        const emailAddresses = Object.keys(contacts.email);
        const phoneNumbers = Object.keys(contacts.sms);

        const emailContacts = emailAddresses.map(c => ({
            id: c,
            type: 'email',
            alert: contacts.email[c].alert,
            otp: contacts.email[c].otp
        }));

        const smsContacts = phoneNumbers.map(c => ({
            id: c,
            type: 'sms',
            alert: contacts.sms[c].alert,
            otp: contacts.sms[c].otp
        }));

        return [...emailContacts, ...smsContacts];
    }
}

export default UtilService;
