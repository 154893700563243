
    import Component, { mixins } from 'vue-class-component';
    import { ModelSync, Prop } from 'vue-property-decorator';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import ProfileMixin from '@/components/profile/common/ProfileMixin';
    import VCardTitleWithClose from '@/components/profile/common/dialog/VCardTitleWithClose.vue';

    enum Page {
        None,
        LastContact,
        MultipleContacts,
        ReassignContact,
        ReassignConfirm
    }

    @Component({
        inheritAttrs: false,
        components: {
            VCardTitleWithClose
        }
    })
    export default class RemoveContactDialog extends mixins(ProfileMixin) {
        @ModelSync('showDialog', 'show-dialog-change', { type: Boolean })
        syncedShowDialog!: boolean;

        @Prop({ type: Object, required: true })
        readonly contactToRemove!: Contact;

        @Prop({ type: Array, required: true })
        readonly contacts!: Contact[];

        // *** Data ***
        ContactType = ContactType; // Make available to html template
        Page = Page; // Make the Page enum available to the html template

        currentPage: Page = Page.None;
        reassignToContact: Contact | null = null;
        valid = true;

        // *** Computed Properties ***
        get validation(): Record<string, any> {
            return {
                reassignmentRules: [
                    (v: Contact) => !!v || this.$t('validation.required', { label: this.$t('profile.contact-management.remove.replacement-contact') })
                ]
            };
        }

        get alertContacts(): Contact[] {
            return this.contacts.filter(contact => contact.alert);
        }

        get otpContacts(): Contact[] {
            return this.contacts.filter(contact => contact.otp);
        }

        get isLastAlertContact(): boolean {
            return this.alertContacts.length === 1 && this.alertContacts.includes(this.contactToRemove);
        }

        get isLastOtpContact(): boolean {
            return this.otpContacts.length === 1 && this.otpContacts.includes(this.contactToRemove);
        }

        // *** Lifecycle Methods ***
        created() {
            // Three scenarios
            // 1. User is attempting to remove their last contact
            // 2. User is attempting to remove a contact with Alerts/OTP, but has another Alerts/OTP contact available
            // 3. User is attempting to remove the only contact used for Alerts/OTP
            if (this.contacts.length === 1) {
                this.currentPage = Page.LastContact;
            } else if (!this.isLastAlertContact && !this.isLastOtpContact) {
                this.currentPage = Page.MultipleContacts;
            } else {
                this.currentPage = Page.ReassignContact;
            }
        }

        // *** Methods ***
        closeDialog(): void {
            this.syncedShowDialog = false;
        }

        getFormattedContact(contact: Contact): string {
            return contact.type === ContactType.Sms ? this.$util.getFormattedPhone(contact.id as string) : contact.id as string;
        }

        proceedToReassignConfirm(): void {
            if (!this.valid) {
                (this.$refs.form as any).validate();
                return;
            }

            this.currentPage = Page.ReassignConfirm;
        }

        deleteContact(): void {
            this.$emit('delete', this.contactToRemove);
            this.syncedShowDialog = false;
        }

        async reassignAndDeleteContact(): Promise<void> {
            const contactCopy: Contact = { ...this.reassignToContact! };

            if (this.isLastAlertContact) {
                contactCopy.alert = true;
            }

            if (this.isLastOtpContact) {
                contactCopy.otp = true;
            }

            try {
                await this.createContactReq(contactCopy);
                this.$emit('update', contactCopy);
            } catch (error) {
                this.$notification.error({
                    title: this.$t('errors.contact-info-reassign.title') as string,
                    message: this.$t('errors.contact-info-reassign.message') as string
                }, error);
            }

            this.deleteContact();
        }
    }
