
    import Component from 'vue-class-component';
    import { CallbackType, PasswordCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';
    import TreeDisplay from '@/components/TreeDisplay.vue';

    @Component({
        components: {
            GenericCallbackRenderer,
            TreeDisplay
        }
    })
    export default class EnterUserPassword extends Stage {
        // *** Data ***
        supportEmail = this.$root.$t('common.trivir-email-general');

        // *** Lifecycle Methods ***
        created() {
            // Customize callback component rendering via createElement() data object
            const passwordCallback = this.$util.am.getCallbackOfType<PasswordCallback>(this.callbacks, CallbackType.PasswordCallback);
            passwordCallback.createElementData = {
                props: {
                    passwordTextFieldMode: 'current'
                }
            };
        }
    };
