
    import Component from 'vue-class-component';
    import Profile from '@/components/profile/Profile';

    enum State {
        ChangeChallengeQuestionsAndAnswers,
        ChallengeQuestionsAndAnswersChangeSuccessful
    }

    @Component
    export default class ChangeChallengeQuestions extends Profile {
        // *** Data ***
        questionId1 = '';
        questionId2 = '';
        questionId3 = '';

        answer1 = '';
        answer2 = '';
        answer3 = '';

        state = State.ChangeChallengeQuestionsAndAnswers;
        valid = true;

        response = {
            challengeQuestionGroups: [
                {
                    groupName: '',
                    retired: false,
                    questions: [
                        {
                            questionId: '',
                            questionText: '',
                            userAnswer: null as string | null
                        }
                    ]
                }
            ]
        };

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        questionRules = [
            (v: any) => !!v || this.$root.$t('validation.required-selection')
        ];

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        answerRules = [
            (v: any) => !!v || this.$root.$t('validation.required', { label: this.$root.$t('common.kba-setup.answer-label') })
        ];

        // *** Computed Properties ***
        get showSuccessMessage(): boolean {
            return this.state === State.ChallengeQuestionsAndAnswersChangeSuccessful;
        }

        // *** Lifecycle Methods ***
        created() {
            this.getKbaInfo();
        }

        // *** Methods ***
        getQuestionIndex(value: Array<Record<string, any>>, dataVar: any) {
            const index = value.findIndex((e) => e.questionId === dataVar);
            return index !== -1 ? index : 0;
        }

        async getKbaInfo() {
            this.submitInProgress = true;

            const config = {
                withCredentials: true
            };

            try {
                const response = await this.$axios.get(`${this.realmUrlPath}/afcu/user/me/challenge`, config);
                this.response = Object.assign({}, this.response, response.data);
            } catch (error) {
                this.$notification.error({
                    title: this.$t('errors.kba-profile-retrieve.title') as string,
                    message: this.$t('errors.kba-profile-retrieve.message') as string
                }, error);
            } finally {
                this.submitInProgress = false;
            }
        }

        async save() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
            } else if (this.valid && !this.submitInProgress) {
                this.submitInProgress = true;

                const data = { ...this.response };
                data.challengeQuestionGroups[0].questions[this.getQuestionIndex(data.challengeQuestionGroups[0]
                    .questions, this.questionId1)].userAnswer = this.answer1;
                data.challengeQuestionGroups[1].questions[this.getQuestionIndex(data.challengeQuestionGroups[1]
                    .questions, this.questionId2)].userAnswer = this.answer2;
                data.challengeQuestionGroups[2].questions[this.getQuestionIndex(data.challengeQuestionGroups[2]
                    .questions, this.questionId3)].userAnswer = this.answer3;

                const config = {
                    headers: {
                        'Accept-API-Version': 'resource=1.0',
                        'If-Match': '*'
                    },
                    withCredentials: true
                };

                try {
                    await this.$axios.put(`${this.realmUrlPath}/afcu/user/me/challenge`, data, config);
                    this.state = State.ChallengeQuestionsAndAnswersChangeSuccessful;
                } catch (error) {
                    this.$notification.error({
                        title: this.$t('errors.kba-profile-save.title') as string,
                        message: this.$t('errors.kba-profile-save.message') as string
                    }, error);
                } finally {
                    this.submitInProgress = false;
                }
            }
        }
    }
