
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import Stage from '@/components/user-self-service/stage/Stage';
    import NewAndConfirmPasswordFields from '@/components/common/NewAndConfirmPasswordFields.vue';

    @Component({
        components: {
            NewAndConfirmPasswordFields
        }
    })
    export default class CustomUserRegistrationInfo extends Stage {
        // *** Data ***
        autofocusUsername = true; // Workaround for bug: https://github.com/vuetifyjs/vuetify/issues/12989
        badAccountOrPIN = false;
        emailAddress = '';
        emailAddressVisited = false;
        mobilePhone = '';
        mobilePhoneVisited = false;
        password = '';
        confirmPassword = '';
        pin = '';
        pinLength = 4;
        pinVisited = false;
        username = '';
        usernameAvailable: boolean | null | undefined = true;
        unavailableUsername: string | null = ''; // The username that was found to be unavailable

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        usernameLabel = this.$root.$t('register.custom-user-registration-info.username-label');

        // *** Computed Properties ***
        get validation(): Record<string, any> {
            return {
                pinRules: [
                    (v: string) => !!v || this.$t('validation.required', { label: this.$t('register.custom-user-registration-info.pin') }),
                    (v: string) => v.length === this.pinLength || this.$t('validation.length-digits', { label: this.$t('common.pin'), length: this.pinLength })
                ],
                usernameRules: [
                    (v: string) => !!v || this.$t('validation.required', { label: this.usernameLabel }),
                    // NOTE: Don't make the following logic statement truthy/falsy. This needs to evaluate to true if
                    // usernameAvailable is null or undefined (first time on page, no check has been performed yet) or
                    // usernameAvailable is true (the check has been made server-side and is known to be available).
                    () => this.usernameAvailable !== false || this.$t('validation.username-unavailable')
                ]
            };
        }

        // *** Watch Methods ***
        @Watch('username')
        onUsernameChange(newVal: string) {
            if (newVal === this.unavailableUsername) {
                this.usernameAvailable = false;
            } else {
                // Assume the username is available (we don't set to 'true' because we only use that when we know for
                // sure it is available...which we won't know until the user submits the form).
                this.usernameAvailable = null;
            }
        }

        @Watch('ussResponseData')
        onUssResponseDataChange(newUssResponseData: Record<string, any>) {
            this.initFormData(newUssResponseData);
        }

        // *** Lifecycle Methods ***
        mounted() {
            // mounted() is used instead of created() because initFormData() uses a $ref which isn't defined until mounted()
            if (this.ussResponseData.requirements) {
                this.initFormData(this.ussResponseData);
            }
        }

        // *** Methods ***
        initFormData(ussResponseData: Record<string, any>) {
            const stateProvided = Object.keys(ussResponseData.requirements.state).length > 0;

            this.badAccountOrPIN = ussResponseData.requirements.state.badAccountOrPIN || false;
            this.emailAddress = ussResponseData.requirements.state.emailAddress || '';
            this.emailAddressVisited = stateProvided;
            this.mobilePhone = ussResponseData.requirements.state.mobilePhone || '';
            this.mobilePhoneVisited = stateProvided;
            this.password = ussResponseData.requirements.state.password || '';
            this.confirmPassword = ussResponseData.requirements.state.password || '';
            this.pin = ussResponseData.requirements.state.pin || '';
            this.pinVisited = stateProvided;
            this.username = ussResponseData.requirements.state.username || '';
            this.usernameAvailable = ussResponseData.requirements.state.usernameAvailable;
            this.unavailableUsername = (this.usernameAvailable || this.username === '') ? null : this.username;

            if (stateProvided) {
                (this.$refs.form as any).validate();
            } else {
                // This supports session timeout scenarios where the component needs to be reset
                (this.$refs.form as any).resetValidation();
                (this.$refs.username as any).focus();
            }

            if (this.badAccountOrPIN && this.$notification.count() <= 0) {
                this.$notification.error({
                    title: this.$t('errors.register-bad-account-info.title') as string,
                    message: this.$t('errors.register-bad-account-info.message') as string
                });
            }
        }

        getInputData(): Record<string, any> {
            return {
                username: this.username,
                emailAddress: this.emailAddress.toLowerCase(),
                mobilePhone: this.mobilePhone,
                password: this.password,
                pin: this.pin
            };
        }

        cancel() {
            if (!this.submitInProgress) {
                this.$emit('cancel');
            }
        }
    }
