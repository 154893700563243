
    import Component from 'vue-class-component';
    import Stage from '@/components/user-self-service/stage/Stage';

    @Component
    export default class CustomKbaSetup extends Stage {
        // *** Data ***
        questionId1 = '';
        questionId2 = '';
        questionId3 = '';

        answer1 = '';
        answer2 = '';
        answer3 = '';

        timeout = 63000;

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        questionRules = [
            (v: any) => !!v || this.$root.$t('validation.required-selection')
        ];

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        answerRules = [
            (v: any) => !!v || this.$root.$t('validation.required', { label: this.$root.$t('common.kba-setup.answer-label') }),
            (v: any) => this.$util.constants.KBA_ANSWERS_REGEX.test(v) || this.$root.$t('validation.alphanumeric')
        ];

        // *** Methods ***
        getInputData(): Record<string, any> {
            return {
                question1: this.questionId1,
                answer1: this.answer1,
                question2: this.questionId2,
                answer2: this.answer2,
                question3: this.questionId3,
                answer3: this.answer3
            };
        }

        getRequestConfigProps(): Record<string, any> {
            return {
                timeout: this.timeout
            };
        }
    }
