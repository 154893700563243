import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FRCallback } from '@forgerock/javascript-sdk';

// @ts-ignore
@Component
export default abstract class Callback extends Vue {
    @Prop({ type: String, required: true })
    readonly id!: string;

    @Prop({ type: Boolean, default: false })
    readonly autoFocus!: boolean;

    // *** Methods ***
    abstract isFocusable(): boolean; // Utilized by GenericCallbackRenderer (must be a method rather than data variable). Don't use 'this' in this method.

    abstract supportsCallback(callback: FRCallback): boolean; // Utilized by GenericCallbackRenderer. Don't use 'this' in this method.
}
