
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback, NameCallback as SDKNameCallback } from '@forgerock/javascript-sdk';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';
    import NameCallback from '@/components/auth/callback/NameCallback.vue';
    import Stage from '@/components/auth/stage/Stage';

    @Component({
        components: {
            ConfirmationCallback,
            NameCallback
        }
    })
    export default class TotpVerification extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;
        nameCallback: SDKNameCallback | null = null;

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
                this.nameCallback = this.$util.am.getCallbackOfType<SDKNameCallback>(callbacks, CallbackType.NameCallback);
            } catch (error) {
                // TODO - Replace title/message
                this.$notification.error({
                    title: 'Error',
                    message: 'REPLACE_ME'
                });
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
