
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback, HiddenValueCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
    import QRCode, { QRCodeRenderersOptions } from 'qrcode';
    import Stage from '@/components/auth/stage/Stage';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class TotpRegistration extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;
        hiddenValueCallback: HiddenValueCallback | null = null;
        textOutputCallbacks: TextOutputCallback[] = [];

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        mounted() {
            const options: QRCodeRenderersOptions = {
                errorCorrectionLevel: 'L',
                version: 20
            };

            QRCode.toCanvas(this.$refs.canvas, this.hiddenValueCallback!.getOutputValue() as string, options, error => {
                if (error) {
                    this.$notification.error({
                        title: 'Error',
                        message: 'Unable to display QR code.'
                    });
                }
            });
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
                this.hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(callbacks, CallbackType.HiddenValueCallback);
                this.textOutputCallbacks = this.$util.am.getCallbacksOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);
            } catch (error) {
                console.error(error);
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
