
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, PasswordCallback } from '@forgerock/javascript-sdk';
    import NewStage from '@/components/user-self-service/stage/NewStage';
    import NewAndConfirmPasswordFields from '@/components/common/NewAndConfirmPasswordFields.vue';

    @Component({
        components: {
            NewAndConfirmPasswordFields
        }
    })
    export default class PasswordReset extends NewStage {
        // *** Data ***
        passwordCallback: PasswordCallback | null = null;
        confirmPasswordCallback: PasswordCallback | null = null;

        // *** Computed Properties ***
        get password(): string {
            return this.passwordCallback ? this.passwordCallback.getInputValue() as string : '';
        }

        set password(password: string) {
            if (this.passwordCallback) {
                this.passwordCallback.setInputValue(password);
            }
        }

        get confirmPassword(): string {
            return this.confirmPasswordCallback ? this.confirmPasswordCallback.getInputValue() as string : '';
        }

        set confirmPassword(password: string) {
            if (this.confirmPasswordCallback) {
                this.confirmPasswordCallback.setInputValue(password);
            }
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            const passwordCallbacks: PasswordCallback[] = this.$util.am.getCallbacksOfType(callbacks, CallbackType.PasswordCallback);
            this.passwordCallback = passwordCallbacks.find((c: PasswordCallback) => c.getPrompt() === 'newPassword') || null;
            this.confirmPasswordCallback = passwordCallbacks.find((c: PasswordCallback) => c.getPrompt() === 'confirmPassword') || null;
        }
    }
