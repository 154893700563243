
    import Component from 'vue-class-component';
    import Stage from '@/components/user-self-service/stage/Stage';

    @Component
    export default class EmailValidation extends Stage {
        // *** Methods ***
        getInputData(): Record<string, any> {
            return {};
        }
    }
