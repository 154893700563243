import { PluginObject } from 'vue';
import focusDirective from '@/directives/focus';
import visibleDirective from '@/directives/visible';
import notificationService from '@/services/notification';
import UtilService from '@/services/util';

const UtilsPlugin: PluginObject<any> = Object.freeze({
    install: function(Vue) {
        Vue.directive('focus', focusDirective);
        Vue.directive('visible', visibleDirective);
        Vue.prototype.$notification = notificationService;
        Vue.prototype.$util = UtilService;
    }
});

export default UtilsPlugin;
