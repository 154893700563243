
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, NameCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';

    @Component
    export default class ContactInfoVerification extends Stage {
        // *** Data ***
        emailNameCallback: NameCallback | null = null;
        mobilePhoneNameCallback: NameCallback | null = null;

        emailAddressVisited = false;
        mobilePhoneVisited = false;

        // *** Computed Properties ***
        get emailAddress(): string {
            return this.emailNameCallback ? this.emailNameCallback.getInputValue() as string : '';
        }

        set emailAddress(emailAddress: string) {
            if (this.emailNameCallback) {
                this.emailNameCallback.setInputValue(emailAddress);
            }
        }

        get mobilePhone(): string {
            return this.mobilePhoneNameCallback ? this.mobilePhoneNameCallback.getInputValue() as string : '';
        }

        set mobilePhone(mobilePhone: string) {
            if (this.mobilePhoneNameCallback) {
                this.mobilePhoneNameCallback.setInputValue(mobilePhone);
            }
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data
            this.emailAddressVisited = false;
            this.mobilePhoneVisited = false;

            // Re-init Data
            const nameCallbacks: NameCallback[] = this.$util.am.getCallbacksOfType(callbacks, CallbackType.NameCallback);
            for (const nameCallback of nameCallbacks) {
                const prompt = nameCallback.getPrompt();
                if (prompt === 'mailAddress') {
                    this.emailNameCallback = nameCallback;
                } else if (prompt === 'mobilePhone') {
                    this.mobilePhoneNameCallback = nameCallback;
                }
            }
        }
    }
