
    import { VNode } from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, HiddenValueCallback as SDKHiddenValueCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    @Component
    export default class HiddenValueCallback extends Callback {
        @Prop()
        callback!: SDKHiddenValueCallback;

        // *** Methods ***
        isFocusable(): boolean {
            return false;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.HiddenValueCallback;
        }

        // *** Render Method ***
        render(): VNode {
            // Make this a renderless component
            return this.$slots.default! as any;
        }
    };
