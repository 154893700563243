
    import { VNode } from 'vue';
    import Component from 'vue-class-component';
    import { CallbackType, FRCallback, HiddenValueCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';

    @Component
    export default class VerosintIP extends Stage {
        // *** Data ***
        hiddenValueCallback: HiddenValueCallback | null = null;

        // *** Lifecycle Methods ***
        async created() {
            this.initCallbackData(this.callbacks);

            try {
                const res = await fetch('https://api.ipify.org/?format=json');
                const data = await res.json();
                this.hiddenValueCallback?.setInputValue(data.ip);
                this.next();
            } catch (error) {
                console.error(error);
            }
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(callbacks, CallbackType.HiddenValueCallback);
            } catch (error) {
                console.error(error);
            }
        }

        // *** Render Method ***
        render(): VNode {
            // Make this a renderless component
            return this.$slots.default! as any;
        }
    }
