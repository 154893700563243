
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';

    const ALL_DIGITS_REGEX = /\d/g;
    const DATE_REGEX = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const PLACEHOLDER_STRING = 'placeholder-until-next-tick';

    @Component({
        inheritAttrs: false
    })
    export default class VTextFieldDate extends Vue {
        @Prop({ type: String, required: true })
        readonly value!: string;

        @Prop({ type: Boolean, default: false })
        readonly visited!: boolean;

        @Prop({ type: Boolean, default: false })
        readonly required!: boolean;

        @Prop({ type: Array, default: () => [] })
        readonly rules!: any[];

        // *** Data ***
        dateUnformatted = this.value ? this.getUnformattedDate(this.value) : '';
        syncedVisited = false;

        // *** Computed Properties ***
        get dateRules(): any[] {
            const regexRule = (v: string) => DATE_REGEX.test(v) || this.$t('validation.date-format');
            const validDateRule = () => this.validDate || this.$t('validation.date-invalid');

            let rules: any[];
            if (this.required) {
                let requiredRule;
                if (this.$attrs.label) {
                    requiredRule = (v: string) => !!v || this.$t('validation.required', { label: this.$attrs.label });
                } else {
                    requiredRule = (v: string) => !!v || this.$t('validation.required-simple');
                }
                rules = [requiredRule, regexRule, validDateRule].concat(this.rules);
            } else {
                rules = [regexRule, validDateRule].concat(this.rules);
            }

            return rules;
        }

        get date(): string {
            if (this.dateUnformatted === PLACEHOLDER_STRING) {
                return PLACEHOLDER_STRING;
            }

            const ud = this.dateUnformatted; // Unformatted Date
            const length = ud.length;
            let formattedDate;

            if (length < 3) {
                formattedDate = `${ud}`;
            } else if (length <= 4) {
                formattedDate = `${ud.substring(0, 2)}/${ud.substring(2)}`;
            } else {
                formattedDate = `${ud.substring(0, 2)}/${ud.substring(2, 4)}/${ud.substring(4, 8)}`;
            }

            return formattedDate;
        }

        set date(date) {
            this.dateUnformatted = PLACEHOLDER_STRING;
            const result = date.match(ALL_DIGITS_REGEX);

            this.$nextTick(() => {
                this.dateUnformatted = result ? result.join('') : '';
            });
        }

        get validDate(): boolean {
            let ret = false;
            const matchResult = this.date.match(DATE_REGEX);
            if (matchResult) {
                const month = parseInt(matchResult[1]) - 1; // Must subtract 1, month is zero-based
                const day = parseInt(matchResult[2]);
                const year = parseInt(matchResult[3]);
                const date = new Date(year, month, day);
                if (month === date.getMonth() && day === date.getDate() && year === date.getFullYear()) {
                    ret = true;
                }
            }

            return ret;
        }

        // *** Watch Methods ***
        @Watch('value')
        onValueChange(newValue: string) {
            this.date = newValue;
        }

        @Watch('date')
        onDateChange(newValue: string, oldValue: string) {
            if (newValue !== PLACEHOLDER_STRING && newValue !== oldValue) {
                this.$emit('input', newValue);
            }
        }

        @Watch('visited')
        onVisitedChange(newValue: boolean) {
            this.syncedVisited = newValue;
        }

        @Watch('syncedVisited')
        onSyncedVisitedChange(newValue: boolean) {
            if (newValue !== this.visited) {
                this.$emit('update:visited', newValue);
            }
        }

        // *** Methods ***
        getUnformattedDate(formattedDate: string): string {
            const result = formattedDate.match(ALL_DIGITS_REGEX);
            return result ? result.join('') : '';
        }
    }
