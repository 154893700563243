import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Profile extends Vue {
    @Prop({ type: String, required: false })
        goto!: string | undefined;

    // *** Data ***
    readonly realmUrlPath = this.$util.am.getRealmUrlPathFromRealmPath((this.$root as any).config.amRealmPath);

    submitInProgress = false;

    // *** Computed Properties ***
    get gotoComputed(): string { // Use either the route query param or the http query param. Route query param has precedence.
        return this.goto ? this.goto : (this.$root as any).queryParams.goto;
    }

    // *** Methods ***
    proceedToDestination() {
        this.$notification.clearAll();

        if (this.gotoComputed) {
            // Profile Settings dashboard was accessed directly and provided with goto OR individual profile page was
            // accessed directly and provided with goto. Redirect to the 'goto' URL.
            // @ts-ignore
            window.location = this.gotoComputed;
        } else if (this.$route.name === 'ProfileSettings') {
            // Individual profile page accessed indirectly via the Profile Settings dashboard page. Navigate from
            // the individual profile page back to the Profile Settings dashboard page.
            this.$emit('back');
        } else {
            // Individual profile page was accessed directly but was not provided a goto. This should only happen in the
            // dev environment, so just navigate to the Profile Settings dashboard page for lack of anything better to do.
            this.$router.push({ name: 'ProfileSettings' });
        }
    }
}
