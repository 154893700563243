import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        onMobile: false,
        showTree: false,
        step: {}
    },
    mutations: {
        updateOnMobile(state, onMobile) {
            state.onMobile = onMobile;
        },

        updateStep(state, step) {
            state.step = step;
        },

        toggleTreeDisplay(state) {
            state.showTree = !state.showTree;
        }
    }
});
