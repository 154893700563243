
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import ProfileSheet from '@/components/profile/common/ProfileSheet.vue';

    @Component({
        components: {
            ProfileSheet
        }
    })
    export default class ContactInfoSheet extends Vue {
        @Prop()
        readonly icon!: string;

        @Prop()
        readonly title!: string;

        @Prop()
        readonly description!: string;

        @Prop()
        readonly contactType!: ContactType;

        @Prop()
        readonly contacts!: Contact[];

        @Prop({ type: Boolean, default: true })
        readonly allowRemovalOfLastItem!: boolean;

        @Prop()
        readonly submitInProgress!: boolean;

        // *** Data ***
        ContactType = ContactType; // Make available to html template

        // *** Computed Properties ***
        get contactTypeLabel(): string {
            switch (this.contactType) {
                case ContactType.Email:
                    return this.$t('common.contact-info.email-label') as string;
                case ContactType.Oath:
                    return this.$t('common.contact-info.oath-label') as string;
                case ContactType.Passkey:
                    return this.$t('common.contact-info.passkey-label') as string;
                case ContactType.Push:
                    return this.$t('common.contact-info.push-label') as string;
                case ContactType.Sms:
                    return this.$t('common.contact-info.phone-label') as string;
            }
        }

        // *** Methods ***
        getFormattedContactId(contactId: string): string {
            return this.contactType === ContactType.Sms ? this.$util.getFormattedPhone(contactId) : contactId;
        }
    }
