import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { notificationStore } from '@/services/notification';
import ChangeChallengeQuestions from '@/components/profile/kba/ChangeChallengeQuestions.vue';
import ChangePassword from '@/components/profile/password/ChangePassword.vue';
import ChangeUsername from '@/components/profile/username/ChangeUsername.vue';
import ContactManagement from '@/components/profile/manage/ContactManagement.vue';
import ForgotPassword from '@/views/user-self-service/ForgotPassword.vue';
import ForgotUsername from '@/views/user-self-service/ForgotUsername.vue';
import HomeLoan from '@/views/HomeLoan.vue';
import LandingPage from '@/views/LandingPage.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import MFA from '@/components/profile/mfa/Mfa.vue';
import ProfileSettings from '@/views/ProfileSettings.vue';
import Register from '@/views/user-self-service/Register.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: { name: 'Login' }
    },
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage
    },
    {
        path: '/enroll',
        name: 'Register',
        component: Register,
        props: (route: Route) => ({
            helios: route.query.helios
        })
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        props: (route: Route) => ({
            helios: route.query.helios,
            code: route.query.code,
            token: route.query.token
        })
    },
    {
        path: '/forgot-userid',
        name: 'ForgotUsername',
        component: ForgotUsername,
        props: (route: Route) => ({
            helios: route.query.helios
        }),
        redirect: { name: 'Login' }
    },
    {
        path: '/home-loan',
        name: 'HomeLoan',
        component: HomeLoan,
        meta: {
            authRequired: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: (route: Route) => ({
            failureMessage: route.query.failure,
            internalRedirect: route.query['internal-redirect']
        })
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/profile',
        name: 'ProfileSettings',
        component: ProfileSettings,
        props: (route: Route) => ({
            goto: route.query.goto
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/profile/change-challenge-questions',
        name: 'ChangeChallengeQuestions',
        component: ChangeChallengeQuestions,
        props: (route: Route) => ({
            goto: route.query.goto
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/profile/change-password',
        name: 'ChangePassword',
        component: ChangePassword,
        props: (route: Route) => ({
            goto: route.query.goto
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/profile/change-userid',
        name: 'ChangeUsername',
        component: ChangeUsername,
        props: (route: Route) => ({
            goto: route.query.goto
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/profile/mfa',
        name: 'MFA',
        component: MFA,
        props: (route: Route) => ({
            goto: route.query.goto
        }),
        meta: {
            authRequired: true
        }
    },
    {
        path: '/profile/manage',
        name: 'ContactManagement',
        component: ContactManagement,
        props: (route: Route) => ({
            goto: route.query.goto
        }),
        meta: {
            authRequired: true
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    base: '/'
});

// eslint-disable-next-line space-before-function-paren
router.beforeEach(async (to, from, next) => {
    // @ts-ignore
    await router.app.$options.data().appInitializedPromise;

    notificationStore.clearAll(); // Clear all notifications (error, warning, success, and info) by default on route change

    // Auth Required? If so, force login then redirect to the original destination after successful login.
    // @ts-ignore
    if (to.meta.authRequired && !router.app.isAuthenticated) {
        next({ name: 'Login', query: { 'internal-redirect': to.fullPath } });
    } else {
        next();
    }
});

export default router;
