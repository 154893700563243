import { DirectiveOptions } from 'vue';

const focusDirective: DirectiveOptions = {
    inserted: function(el, binding) {
        if (binding.value || binding.value === undefined) {
            el.focus();
        }
    }
};

export default focusDirective;
