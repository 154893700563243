
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, DeviceProfileCallback as SDKDeviceProfileCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    @Component
    export default class DeviceProfileCallback extends Callback {
        @Prop()
        callback!: SDKDeviceProfileCallback;

        // *** Computed Properties ***
        get deviceProfile(): string {
            return this.callback.getInputValue() as string;
        }

        set deviceProfile(location: string) {
            this.callback.setInputValue(location);
        }

        // *** Lifecycle Methods ***
        created() {
            navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);
        }

        // *** Methods ***
        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.DeviceProfileCallback;
        }

        successCallback(position: any): void {
            const deviceProfile = {
                identifier: window.crypto.getRandomValues(new Uint32Array(3)).join('-'), // This is how Forgerock gets the identifier in the XUI
                location: {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude
                }
            };

            this.deviceProfile = JSON.stringify(deviceProfile);
            this.$emit('success');
        }

        errorCallback(error: any): void {
            console.error(error.message);
        }
    }
