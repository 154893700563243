import { render, staticRenderFns } from "./HomeLoan.vue?vue&type=template&id=3865bfc9&scoped=true&"
import script from "./HomeLoan.vue?vue&type=script&lang=js&"
export * from "./HomeLoan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3865bfc9",
  null
  
)

export default component.exports