interface Contact {
    type: string | null;
    id: string | null;
    verifiedOn?: string | null;
    alert: boolean;
    otp: boolean;
}

enum ContactType {
    Sms = 'sms',
    Email = 'email',
    Oath = 'oath',
    Passkey = 'passkey',
    Push = 'push'
}

interface OathDevice {
    id: string;
    type: string;
    uuid: string;
}

interface Passkey {
    id: string;
    type: string;
    uuid: string;
}

interface PushDevice {
    id: string;
    type: string;
    uuid: string;
}

export { Contact, ContactType, OathDevice, Passkey, PushDevice };
