
    import Component from 'vue-class-component';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class RestartJourney extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        mounted() {
            (document.querySelector('.loading')!.nextElementSibling as HTMLElement).style.display = 'none';

            const clickBtn = setInterval(() => {
                const btn = document.querySelector('#confirmation-callback-1-0') as HTMLButtonElement;
                if (btn) {
                    btn.click();
                } else {
                    clearInterval(clickBtn);
                }
            }, 300);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
            } catch (error) {
                console.error(error);
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
