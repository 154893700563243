
    import Component from 'vue-class-component';
    import Stage from '../Stage';

    @Component
    export default class Maintenance extends Stage {
        // *** Methods ***
        getInputData(): Record<string, any> {
            return {};
        }

        ok() {
            if (!this.submitInProgress) {
                this.$emit('cancel');
            }
        }
    }
