<template>
    <content-wrapper>
        <v-form>
            <template v-if="currentPage === 0">
                <h1 class="text-h4">Property Info</h1>
                <v-select
                    v-model="homeType"
                    :items="homeTypes"
                    :autofocus="true"
                    label="Type of home"
                />
                <v-select
                    v-model="homeUse"
                    :items="homeUses"
                    label="Home use"
                />
                <v-text-field
                    v-model="county"
                    label="Location (County)"
                />
            </template>

            <template v-if="currentPage === 1">
                <h1 class="text-h4">Loan Info</h1>
                <v-text-field
                    v-model="purchasePrice"
                    :autofocus="true"
                    label="Purchase price"
                />
                <v-text-field
                    v-model="downPayment"
                    label="Down payment"
                />
            </template>

            <template v-if="currentPage === 2">
                <h1 class="text-h4">Financial Info</h1>
                <v-select
                    v-model="employmentStatus"
                    :items="employmentStatuses"
                    :autofocus="true"
                    label="Employment status"
                />
                <v-text-field
                    v-model="employmentStartDate"
                    label="Start of employment"
                />
                <v-text-field
                    v-model="annualIncome"
                    label="Annual income"
                />
                <v-select
                    v-model="creditScore"
                    :items="creditScores"
                    label="Credit score"
                />
            </template>

            <!--Confirmation page-->
            <template v-if="currentPage === 3">
                <h1 class="mb-4">Thanks for completing your application!</h1>
                <div class="text-center">
                    <v-icon color="primary" class="mb-4 text-h1">mdi-email-outline</v-icon>
                    <p>You will receive an email sent to a****@gmail.com in 1-2 weeks informing you of our decision.</p>
                </div>
            </template>

            <div v-if="currentPage !== 3" class="d-flex mt-4">
                <v-btn v-if="currentPage !== 0" id="btn-back" color="white" class="mr-5" @click="currentPage--">{{ $t('common.back') }}</v-btn>
                <v-btn id="btn-next" type="submit" color="primary" @click="currentPage++">{{ $t('common.next') }}</v-btn>
            </div>
        </v-form>
    </content-wrapper>
</template>

<script>
    export default {
        name: 'HomeLoan',

        data() {
            return {
                currentPage: 0,
                annualIncome: '',
                county: '',
                creditScore: '',
                creditScores: [
                    'Excellent (800+)',
                    'Good (740-799)',
                    'Average (670-739)',
                    'Fair (580-669)',
                    'Poor (579 or lower)'
                ],
                downPayment: '',
                employmentStartDate: '',
                employmentStatus: '',
                employmentStatuses: [
                    'Employment',
                    'Military',
                    'Business/Self-employment',
                    'Independent Contractor',
                    'Other'
                ],
                homeType: '',
                homeTypes: [
                    'Single Family',
                    'Condo',
                    'Townhouse',
                    'Commercial',
                    'Other'
                ],
                homeUse: '',
                homeUses: [
                    'Primary Home',
                    'Secondary/Vacation Home',
                    'Investment/Rental Home',
                    'Other'
                ],
                purchasePrice: ''
            };
        },

        methods: {
            onNextClick() {
                if (this.currentPage < 2) {
                    this.currentPage++;
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
