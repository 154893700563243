
    import { CreateElement, VNode } from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, TextOutputCallback as SDKTextOutputCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    enum MessageType {
        INFORMATION = 0,
        WARNING = 1,
        ERROR = 2
    }

    @Component
    export default class TextOutputCallback extends Callback {
        @Prop({ type: Object as () => SDKTextOutputCallback, required: true })
        callback!: SDKTextOutputCallback;

        // *** Computed Properties ***
        get callbackOutputValue(): string {
            return this.callback.getOutputValue() as string;
        }

        get renderAsNotification(): boolean {
            // If the first character of the output value is a space render it as a toast notification rather than a
            // normal text output callback (i.e. inline paragraph in the page).
            return this.callbackOutputValue.charAt(0) === ' ';
        }

        get textOutputValue(): string {
            const textOutputValue = this.callbackOutputValue.trim();

            if (this.$util.localeMessageDefined(textOutputValue)) {
                return this.$t(textOutputValue) as string;
            } else {
                return textOutputValue;
            }
        }

        // *** Lifecycle Methods ***
        created() {
            if (this.renderAsNotification) {
                const messageType = parseInt(this.callback.getMessageType());
                switch (messageType) {
                    case MessageType.ERROR:
                        this.$notification.error({ message: this.textOutputValue });
                        break;
                    case MessageType.WARNING:
                        this.$notification.warning({ message: this.textOutputValue });
                        break;
                    case MessageType.INFORMATION:
                        this.$notification.info({ message: this.textOutputValue });
                        break;
                }
            }
        }

        // *** Methods ***
        isFocusable(): boolean {
            return false;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.TextOutputCallback;
        }

        // *** Render Method ***
        render(createElement: CreateElement): VNode {
            if (this.renderAsNotification) {
                // Make this a renderless component (i.e. render it as a snackbar notification instead...see created())
                return this.$slots.default! as any;
            } else {
                // Render as a paragraph
                return createElement(
                    'p',
                    {
                        attrs: {
                            id: this.id
                        }
                    },
                    this.textOutputValue
                );
            }
        }
    }
