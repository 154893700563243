
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, NameCallback as SDKNameCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    @Component
    export default class NameCallback extends Callback {
        @Prop({ type: Object as () => SDKNameCallback, required: true })
        readonly callback!: SDKNameCallback;

        @Prop({ type: String, required: false })
        readonly label!: string;

        @Prop({ type: Boolean, default: false })
        readonly masked!: boolean;

        @Prop({ type: Boolean, default: true })
        readonly required!: boolean;

        @Prop({ type: String, required: false })
        readonly requiredLabel!: string;

        // *** Computed Properties ***
        get inputLabel(): string {
            return this.label ? this.label : this.callback.getPrompt();
        }

        get rules(): Array<any> {
            let rules: Array<any>;

            if (!this.required) {
                rules = [];
            } else if (this.label || this.requiredLabel) {
                const requiredLabel = this.requiredLabel ? this.requiredLabel : this.label;
                rules = [
                    (v: any) => !!v || this.$t('validation.required', { label: requiredLabel })
                ];
            } else {
                rules = [
                    (v: any) => !!v || this.$t('validation.required-simple')
                ];
            }

            return rules;
        }

        get inputValue(): string {
            return this.callback.getInputValue() as string;
        }

        set inputValue(value: string) {
            this.callback.setName(value);
        }

        // *** Methods ***
        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.NameCallback;
        }
    };
