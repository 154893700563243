
    import Component from 'vue-class-component';
    import UserSelfServiceFlow, { FlowType } from '@/views/user-self-service/UserSelfServiceFlow';
    import SessionTimeoutDialog from '@/components/user-self-service/SessionTimeoutDialog.vue';

    import ActivityAudit from '@/components/user-self-service/stage/forgot-username/ActivityAudit.vue';
    import CustomEnterUserDetails from '@/components/user-self-service/stage/shared/CustomEnterUserDetails.vue';
    import OtpVerification from '@/components/user-self-service/stage/shared/OtpVerification.vue';

    enum ErrorType {
        UnableToValidateIdentity,
        Unknown
    }

    @Component({
        components: {
            ActivityAudit,
            CustomEnterUserDetails,
            OtpVerification,
            SessionTimeoutDialog
        }
    })
    export default class ForgotUsername extends UserSelfServiceFlow {
        // *** Data ***
        readonly flowType: FlowType = FlowType.ForgotUsername;
        readonly stageTypesWithSubStages: Array<string> = [];
        readonly ussResponseTypeMappings: Record<string, string> = {
            activityAuditStage: 'ActivityAudit',
            customUserQuery: 'CustomEnterUserDetails',
            customOtpVerification: 'OtpVerification'
        };

        // *** Computed Properties ***
        get ussUrl(): string {
            return `${this.realmUrlPath}/selfservice/forgottenUsername`;
        }

        // *** Methods ***
        handleSubmitInitialRequestErrorGeneric(error: any): void {
            this.$notification.error({
                title: this.$t('errors.username-self-service-initial.title') as string,
                message: this.$t('errors.username-self-service-initial.message') as string
            }, error);
        }

        handleFlowSpecificError(error: any): void {
            const flowSpecificErrorType = this.getFlowSpecificErrorType(error);

            switch (flowSpecificErrorType) {
                case ErrorType.UnableToValidateIdentity:
                    this.$notification.error({
                        message: 'We are unable to validate your identity by the answers you provided. Please call our member service representatives at 1-800-999-3961 Monday-Friday 8 a.m. to 8p.m., Saturday 8 a.m. to 7 p.m. for assistance.'
                    }, error);
                    break;
                default: // ErrorType.Unknown
                    this.$notification.error({
                        title: this.$t('errors.password-self-service-submit.title') as string,
                        message: this.$t('errors.password-self-service-submit.message') as string
                    }, error);
                    break;
            }
        }

        private getFlowSpecificErrorType(error: any): ErrorType {
            if (error?.response?.data) {
                const responseData = error.response.data;

                if (responseData.code === 400 && responseData.message === 'The user details that were provided are not correct') {
                    return ErrorType.UnableToValidateIdentity;
                }
            }

            return ErrorType.Unknown;
        }

        onCompletion() {
            // TODO - Do we need to set a helios-specific goto URL like the other self-service flows?
            this.$router.push({ name: 'Login' });
        }
    };
