import { render, staticRenderFns } from "./ProfileSheet.vue?vue&type=template&id=28ed3693&scoped=true&"
import script from "./ProfileSheet.vue?vue&type=script&lang=ts&"
export * from "./ProfileSheet.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileSheet.vue?vue&type=style&index=0&id=28ed3693&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ed3693",
  null
  
)

export default component.exports