
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, ConfirmationCallback as SDKConfirmationCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class OnlineBankingPrompt extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange() {
            this.init();
        }

        // *** Lifecycle Methods ***
        created() {
            this.init();
        }

        // *** Methods ***
        init() {
            this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(this.callbacks, CallbackType.ConfirmationCallback);
        }
    };
