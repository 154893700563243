import Vue from 'vue';
import i18n from '@/i18n';
import UtilService from '@/services/util';

interface NotificationOptions {
    color?: string; // Can be name of color or a css color (hex or rgb)
    closeText?: string;
    icon?: string; // E.g. mdi-information-outline
    message: string;
    timeout?: number; // Timeout in milliseconds
    title?: string;
}

class Notification {
    // Minimum display time before notificationStore.clearAll() on route change, etc can clear/close the notification.
    private static readonly MINIMUM_DISPLAY_TIME_BEFORE_PROGRAMMATIC_CLOSE = 1000;

    public color?: string;
    public closeText?: string;
    public displayTime: number | undefined;
    public icon?: string;
    public readonly message: string;
    public timeout?: number;
    public readonly title: string | undefined;
    public readonly type: string;

    // This promise represents when this notification has been acknowledged by the user (explicit in cases where the
    // notification as shown as a modal but implicit in cases where it is shown as a snackbar).
    private readonly _acknowledgePromise: Promise<void>;
    private _acknowledgePromiseResolveFunc!: () => void;
    private _isAcknowledged: boolean;

    constructor(
        type: string,
        { color, closeText, icon, message, timeout, title }: NotificationOptions
    ) {
        this.color = color;
        this.closeText = closeText;
        this.displayTime = undefined; // Recorded time when the notification is shown to the user (undefined = hasn't been displayed yet)
        this.icon = icon;
        this.message = message;
        this.timeout = timeout || 4000;
        this.title = title;
        this.type = type;
        this._acknowledgePromise = new Promise<void>((resolve) => {
            this._acknowledgePromiseResolveFunc = resolve;
        });
        this._isAcknowledged = false;
    }

    public get acknowledgePromise(): Promise<void> {
        return this._acknowledgePromise;
    }

    public acknowledge() {
        if (!this._isAcknowledged) {
            this._isAcknowledged = true;
            this._acknowledgePromiseResolveFunc();
        }
    }

    public minimumDisplayTimeBeforeProgrammaticCloseMet(): boolean {
        if (this.displayTime !== undefined) {
            return (Date.now() - this.displayTime) >= Notification.MINIMUM_DISPLAY_TIME_BEFORE_PROGRAMMATIC_CLOSE;
        } else {
            return false;
        }
    }
}

class NotificationStore {
    private _state: Record<string, any>;

    constructor() {
        // Unfortunately, Vue.observable() can only be used on objects, not arrays, which is why we have to create a 'state' object.
        this._state = Vue.observable({
            notifications: [] as Array<Notification>
        });
    }

    public get notifications(): Array<Notification> {
        return this._state.notifications;
    }

    public add(notification: Notification) {
        this.notifications.push(notification);
    }

    public clearFirst() {
        if (this.notifications.length > 0) {
            this.notifications[0].acknowledge();
            this.notifications.shift();
        }
    }

    public clearAll(force = false) {
        // Handle some edge cases where clearAll() is called automatically on route change, step change, etc and
        // immediately after a notification was displayed (auth timeout error is a good example). A notification that
        // was very recently displayed (under 1 second before clearAll() was called) prevents the notification array
        // from getting cleared.
        if (this.notifications.length > 0 && (force || this.notifications[0].minimumDisplayTimeBeforeProgrammaticCloseMet())) {
            this.notifications.forEach((notification, index) => {
                if (index > 0) {
                    const originalNotificationText = notification.title ? `${notification.title} - ${notification.message}` : notification.message;
                    console.warn(`A notification that was never shown to the user is being cleared. Original notification text: ${originalNotificationText}`);
                }

                notification.acknowledge();
            });
            this._state.notifications = [];
        }
    }
}

class NotificationService {
    private _notificationStore: NotificationStore;

    constructor(notificationStore: NotificationStore) {
        this._notificationStore = notificationStore;
    }

    private getDereferencedMessage(message: string): string {
        const trimmedMessage = message.trim();
        if (UtilService.localeMessageDefined(trimmedMessage)) {
            return i18n.t(trimmedMessage) as string;
        } else {
            return message;
        }
    }

    private addNotification(type: string, notificationOptions: NotificationOptions): Promise<void> {
        if (this._notificationStore.notifications.length > 0) {
            console.warn('Multiple notifications pushed to the notification store.');
        }

        // Dereference message (if the message is an i18n lookup string) and setup generic notification if needed
        notificationOptions.message = this.getDereferencedMessage(notificationOptions.message);
        if (!notificationOptions.message) {
            notificationOptions.title = i18n.t('errors.generic.title') as string;
            notificationOptions.message = i18n.t('errors.generic.message') as string;
        }

        // Add notification to the queue
        const notification = new Notification(type, notificationOptions);
        this._notificationStore.add(notification);

        return notification.acknowledgePromise;
    }

    public success(notificationOptions: NotificationOptions): Promise<void> {
        return this.addNotification('success', notificationOptions);
    }

    public error(notificationOptions?: NotificationOptions, error?: any): Promise<void> {
        // Allow 'error' notification to be called without any arguments. Set a generic title and message in this case.
        if (!notificationOptions) {
            notificationOptions = {
                title: i18n.t('errors.generic.title') as string,
                message: i18n.t('errors.generic.message') as string
            };
        }

        // Log error
        const dereferencedNotificationMessage = this.getDereferencedMessage(notificationOptions.message);
        const logMessage = notificationOptions.title ? `${notificationOptions.title}: ${dereferencedNotificationMessage}` : `${dereferencedNotificationMessage}`;
        logMessage && error ? console.error(logMessage, error) : console.error(logMessage);

        return this.addNotification('error', notificationOptions);
    }

    public warning(notificationOptions: NotificationOptions): Promise<void> {
        // Log warning
        console.warn(`${notificationOptions.title}: ${notificationOptions.message}`);

        return this.addNotification('warning', notificationOptions);
    }

    public info(notificationOptions: NotificationOptions): Promise<void> {
        return this.addNotification('info', notificationOptions);
    }

    public clearAll(force = false) {
        this._notificationStore.clearAll(force);
    }

    public count(): number {
        // Number of notifications queued in the notification store
        return this._notificationStore.notifications.length;
    }
}

const notificationStore = new NotificationStore();
const notificationService = new NotificationService(notificationStore);

export default notificationService;
export { Notification, NotificationOptions, NotificationService, notificationStore };
