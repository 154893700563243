
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { ModelSync } from 'vue-property-decorator';
    import QRCode, { QRCodeRenderersOptions } from 'qrcode';
    import { CallbackType, FRAuth, FRStep, HiddenValueCallback, NameCallback as SDKNameCallback } from '@forgerock/javascript-sdk';
    import { StepOptions } from '@forgerock/javascript-sdk/src/auth/interfaces';
    import NameCallback from '@/components/auth/callback/NameCallback.vue';
    import VCardTitleWithClose from '@/components/profile/common/dialog/VCardTitleWithClose.vue';

    enum Step {
        Registration,
        Verification
    }

    @Component({
        components: {
            NameCallback,
            VCardTitleWithClose
        }
    })
    export default class AddTotpDialog extends Vue {
        @ModelSync('showDialog', 'show-dialog-change', { type: Boolean })
        syncedShowDialog!: boolean;

        // *** Data ***
        Step = Step; // Make enum available in html template

        currentStep: FRStep | undefined;
        nameCallback: SDKNameCallback | null = null;
        step: Step = Step.Registration;

        // *** Lifecycle Methods ***
        async created() {
            await this.registerTotp();
        }

        // *** Methods ***
        closeDialog() {
            this.syncedShowDialog = false;
        }

        async registerTotp() {
            try {
                const options: StepOptions = { tree: 'TOTP' };
                this.currentStep = await FRAuth.next(undefined, options) as FRStep;

                if (this.currentStep.callbacks) {
                    const hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(this.currentStep.callbacks, CallbackType.HiddenValueCallback);

                    const options: QRCodeRenderersOptions = {
                        errorCorrectionLevel: 'L',
                        version: 20
                    };

                    QRCode.toCanvas(this.$refs.canvas, hiddenValueCallback.getOutputValue() as string, options, error => {
                        if (error) {
                            this.$notification.error({
                                title: 'Error',
                                message: 'Unable to display QR code.'
                            });
                        }
                    });
                }
            } catch (error) {
                this.closeDialog();
                this.$notification.error({
                    title: 'Authenticator Error',
                    message: 'Unable to add authenticator app.'
                }, error);
            }
        }

        async proceedToVerification() {
            try {
                const options: StepOptions = { tree: 'TOTP' };
                const response = await FRAuth.next(this.currentStep, options) as FRStep;

                if (response.callbacks) {
                    this.nameCallback = this.$util.am.getCallbackOfType<SDKNameCallback>(response.callbacks, CallbackType.NameCallback);
                    this.step = Step.Verification;
                }
            } catch (error) {
                this.closeDialog();
                this.$notification.error({
                    title: 'Authenticator Error',
                    message: 'Unable to add authenticator app.'
                }, error);
            }
        }

        // This method doesn't actually verify TOTP, it just adds it to the UI
        async verifyTotp() {
            this.$emit('add');
            this.closeDialog();
        }
    }
