
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, PasswordCallback as SDKPasswordCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    @Component
    export default class PasswordCallback extends Callback {
        @Prop({ type: Object as () => SDKPasswordCallback, required: true })
        readonly callback!: SDKPasswordCallback;

        @Prop({ type: String, required: false })
        readonly passwordTextFieldMode!: string;

        // *** Computed Properties ***
        get inputLabel(): string {
            const i18nPasswordLabelPath = 'login.callbacks.password.default-label';
            return this.$util.localeMessageDefined(i18nPasswordLabelPath) ? this.callback.getPrompt() : this.$t(i18nPasswordLabelPath) as string;
        }

        get password(): string {
            return this.callback.getInputValue() as string;
        }

        set password(password: string) {
            this.callback.setPassword(password);
        }

        // *** Methods ***
        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.PasswordCallback;
        }
    };
