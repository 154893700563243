import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { CallbackType, FRCallback, HiddenValueCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
import Stage from '@/components/auth/stage/Stage';

// @ts-ignore
@Component
export default abstract class WebAuthnAuthentication extends Stage {
    // *** Data ***
    hiddenValueCallback: HiddenValueCallback | null = null;
    textOutputCallbacks: TextOutputCallback[] = [];
    script = '';
    title = '';

    // *** Watch Methods ***
    @Watch('callbacks')
    onCallbacksChange(newCallbacks: FRCallback[]) {
        this.initCallbackData(newCallbacks);
    }

    // *** Lifecycle Methods ***
    created() {
        this.initCallbackData(this.callbacks);
    }

    mounted() {
        // eslint-disable-next-line no-new-func
        Function(this.script)();
    }

    // *** Methods ***
    onSubmit(event: Event) {
        event.preventDefault();
        const inputElement = document.getElementById('webAuthnOutcome') as HTMLInputElement;

        if (this.hiddenValueCallback) {
            this.hiddenValueCallback.setInputValue(inputElement.value);
            this.next(true);
        }
    }

    initCallbackData(callbacks: FRCallback[]) {
        try {
            this.hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(callbacks, CallbackType.HiddenValueCallback);
            this.textOutputCallbacks = this.$util.am.getCallbacksOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);

            // There are two TextOutputCallbacks sent by AM. Both contain JavaScript.
            // 1) Initiates the WebAuthn flow and runs when the page is mounted.
            // 2) Places HTML elements and doesn't run because we've already added the needed elements to the <template>.
            this.script = this.textOutputCallbacks[0].payload.output[0].value as string;
        } catch (error) {
            console.error(error); // TODO - Handle error
        }
    }

    // *** Render Method ***

    /*
     * This render method was created because WebAuthnAuthentication and WebAuthnRegistration use (and should use)
     * the exact same template. Now a change can be made to the shared template (i.e. this render method) without
     * having to modify both WebAuthn components (WebAuthnAuthentication, WebAuthnRegistration).
     *
     * The render method is equivalent to the following template:
     *
     *   <template>
     *      <content-wrapper>
     *          <h1 class="text-h4">WebAuthn Authentication</h1>
     *
     *          <span>Waiting for local device...</span>
     *
     *          <!--The IDs in this form are used by the first script contained in the TextOutputCallbacks-->
     *          <form @submit.prevent="onSubmit" hidden>
     *              <input id="webAuthnOutcome" type="text">
     *              <button id="loginButton_0" type="submit"></button>
     *          </form>
     *      </content-wrapper>
     *   </template>
     */
    render(createElement: any) {
        return createElement(
            'content-wrapper',
            [
                createElement(
                    'h1',
                    {
                        attrs: {
                            class: 'text-h4'
                        }
                    },
                    this.title // This is set in each individual component
                ),
                createElement('span', 'Waiting for local device...'),
                createElement(
                    'form',
                    {
                        on: {
                            submit: this.onSubmit
                        }
                    },
                    [
                        createElement(
                            'input',
                            {
                                attrs: {
                                    id: 'webAuthnOutcome',
                                    type: 'hidden'
                                }
                            }
                        ),
                        createElement(
                            'button',
                            {
                                attrs: {
                                    id: 'loginButton_0',
                                    type: 'submit'
                                }
                            }
                        )
                    ]
                )
            ]
        );
    }
}
