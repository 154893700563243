import { DirectiveFunction, DirectiveOptions } from 'vue';

const bindAndUpdateFunc: DirectiveFunction = function(el, binding) {
    // eslint-disable-next-line no-extra-boolean-cast
    el.style.visibility = !!binding.value ? 'visible' : 'hidden';
};

const visibleDirective: DirectiveOptions = {
    bind: bindAndUpdateFunc,
    update: function(el, binding) {
        if (binding.value !== binding.oldValue) {
            // @ts-ignore
            bindAndUpdateFunc(el, binding);
        }
    }
};

export default visibleDirective;
