
    import Component, { mixins } from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import Profile from '@/components/profile/Profile';
    import ProfileMixin from '@/components/profile/common/ProfileMixin';

    @Component
    export default class ContactRow extends mixins(Profile, ProfileMixin) {
        @Prop()
        readonly contact!: Contact;

        @Prop()
        readonly contactType!: ContactType;

        // *** Data ***
        ContactType = ContactType; // Make available to html template

        readonly realmUrlPath = this.$util.am.getRealmUrlPathFromRealmPath((this.$root as any).config.amRealmPath);

        alertsEnabled = false;
        selectedItem: number | null = null;
        items = [
            // { text: 'Set Primary', icon: 'mdi-star-outline' },
            { text: this.$t('common.delete'), icon: 'mdi-delete-outline' }
        ];

        // *** Watch Methods ***
        @Watch('contact')
        onCurrentPageChange(newVal: Contact) {
            this.alertsEnabled = newVal.alert;
        }

        // *** Lifecycle Methods ***
        created() {
            this.alertsEnabled = this.contact.alert;
        }

        // *** Methods ***
        async onItemSelect() {
            this.$emit('delete', this.contact);
            this.$nextTick(() => {
                this.selectedItem = null;
            });
        }

        async updateContact(): Promise<void> {
            // Prevent users from disabling alerts if contact is only being used for alerts
            if (this.contact.alert && !this.contact.otp) {
                this.$nextTick(() => {
                    this.alertsEnabled = true;
                });

                this.$notification.error({
                    title: this.$t('errors.alert-management.title') as string,
                    message: this.$t('errors.alert-management.message', { action: 'disable' }) as string
                });
            } else {
                const contactCopy: Contact = { ...this.contact };
                contactCopy.alert = this.alertsEnabled;
                try {
                    await this.toggleAlertReq(contactCopy);
                    this.$emit('update', contactCopy);
                } catch (error) {
                    this.alertsEnabled = !this.alertsEnabled;

                    this.$notification.error({
                        title: this.$t('errors.alert-management.title') as string,
                        message: this.$t('errors.alert-management.message', { action: this.alertsEnabled ? 'disable' : 'enable' }) as string
                    }, error);
                }
            }
        }
    };
