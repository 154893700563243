
    import Component from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';
    import { FRCallback, HiddenValueCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';
    import util from '@/services/util';

    @Component
    export default class KbaQuestionGroupsCallback extends Callback {
        @Prop()
        callback!: HiddenValueCallback;

        // *** Data ***
        kbaData: Record<string, any> = {};

        questionId1 = '';
        questionId2 = '';
        questionId3 = '';

        answer1 = '';
        answer2 = '';
        answer3 = '';

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        questionRules = [
            (v: any) => !!v || this.$root.$t('validation.required-selection')
        ];

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        answerRules = [
            (v: any) => !!v || this.$root.$t('validation.required', { label: this.$root.$t('common.kba-setup.answer-label') }),
            (v: any) => this.$util.constants.KBA_ANSWERS_REGEX.test(v) || this.$root.$t('validation.alphanumeric')
        ];

        // *** Watch Methods ***
        @Watch('questionId1')
        onQuestionId1Change() {
            this.updateCallbackInputData();
        }

        @Watch('questionId2')
        onQuestionId2Change() {
            this.updateCallbackInputData();
        }

        @Watch('questionId3')
        onQuestionId3Change() {
            this.updateCallbackInputData();
        }

        @Watch('answer1')
        onAnswer1Change() {
            this.updateCallbackInputData();
        }

        @Watch('answer2')
        onAnswer2Change() {
            this.updateCallbackInputData();
        }

        @Watch('answer3')
        onAnswer3Change() {
            this.updateCallbackInputData();
        }

        // *** Lifecycle Methods ***
        created() {
            this.kbaData = JSON.parse(this.callback.getOutputValue() as string);
        }

        // *** Methods ***
        addAnswerToQuestionGroup(questionGroup: Record<string, any>, questionId: string, answer: string) {
            questionGroup.questions = questionGroup.questions.map((questionAnswerObj: Record<string, string>) => {
                if (questionAnswerObj.questionId === questionId) {
                    questionAnswerObj.userAnswer = answer;
                }

                return questionAnswerObj;
            });
        }

        updateCallbackInputData() {
            const inputData = JSON.parse(this.callback.getOutputValue() as string);
            this.addAnswerToQuestionGroup(inputData[0], this.questionId1, this.answer1);
            this.addAnswerToQuestionGroup(inputData[1], this.questionId2, this.answer2);
            this.addAnswerToQuestionGroup(inputData[2], this.questionId3, this.answer3);
            this.callback.setInputValue(JSON.stringify(inputData));
        }

        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && util.am.isKbaQuestionGroupsCallback(callback);
        }
    }
