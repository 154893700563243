
    import Component from 'vue-class-component';
    import Stage from '@/components/user-self-service/stage/Stage';

    @Component
    export default class CustomUserChooseOtpVerificationMethod extends Stage {
        // *** Data ***
        otpVerificationMethod = this.ussResponseData.requirements.state.otpVerificationMethod || null;

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        rules = [
            (v: any) => !!v || this.$root.$t('validation.required-selection')
        ];

        // *** Methods ***
        back() {
            if (!this.submitInProgress) {
                this.submit({
                    otpVerificationMethod: this.otpVerificationMethod,
                    back: true
                });
            }
        }

        getInputData(): Record<string, any> {
            return {
                otpVerificationMethod: this.otpVerificationMethod
            };
        }
    }
