
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';

    @Component({
        inheritAttrs: false
    })
    export default class VTextFieldMasked extends Vue {
        @Prop({ type: Boolean, default: true })
        readonly unmaskable!: boolean;

        // *** Data ***
        showValue = false;
    }
