
    import Component from 'vue-class-component';
    import { CallbackType, FRAuth, FRStep, HiddenValueCallback, PasswordCallback as SDKPasswordCallback } from '@forgerock/javascript-sdk';
    import Profile from '@/components/profile/Profile';
    import NewAndConfirmPasswordFields from '@/components/common/NewAndConfirmPasswordFields.vue';
    import PasswordCallback from '@/components/auth/callback/PasswordCallback.vue';

    enum State {
        ChangePassword,
        PasswordChangeSuccessful
    }

    @Component({
        components: {
            NewAndConfirmPasswordFields,
            PasswordCallback
        }
    })
    export default class ChangePassword extends Profile {
        // *** Data ***
        currentPassword: SDKPasswordCallback | null = null;
        newPassword: SDKPasswordCallback | null = null;
        confirmNewPassword: SDKPasswordCallback | null = null;
        isLoading = true;
        state = State.ChangePassword;
        step: FRStep | undefined;
        valid = true;

        // *** Computed Properties ***
        get showSuccessMessage(): boolean {
            return this.state === State.PasswordChangeSuccessful;
        }

        // *** Lifecycle Methods ***
        async created() {
            this.step = await FRAuth.next(undefined, { tree: 'ChangePassword' }) as FRStep;

            if (this.step.callbacks) {
                const passwordCallbacks = this.$util.am.getCallbacksOfType<SDKPasswordCallback>(this.step.callbacks, CallbackType.PasswordCallback);
                this.currentPassword = passwordCallbacks[0];
                this.newPassword = passwordCallbacks[1];
                this.confirmNewPassword = passwordCallbacks[2];
                this.isLoading = false;
            }
        }

        // *** Methods ***
        async save() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
            } else if (this.valid && !this.submitInProgress) {
                try {
                    this.submitInProgress = true;

                    const response = await FRAuth.next(this.step, { tree: 'ChangePassword' }) as FRStep;
                    const hiddenValueCallback = this.$util.am.getCallbackOfType<HiddenValueCallback>(response.callbacks, CallbackType.HiddenValueCallback);

                    if (hiddenValueCallback.getInputValue() === 'success') {
                        this.state = State.PasswordChangeSuccessful;
                    } else {
                        this.$notification.error({
                            title: this.$t('errors.password-change.title') as string,
                            message: this.$t('errors.password-change.message') as string
                        });
                    }
                } catch (error) {
                    this.$notification.error({
                        title: this.$t('errors.password-change.title') as string,
                        message: this.$t('errors.password-change.message') as string
                    }, error);
                } finally {
                    this.submitInProgress = false;
                }
            }
        }
    }
