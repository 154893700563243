
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';

    @Component
    export default class SettingsOptions extends Vue {
        @Prop({ type: String, required: true })
        readonly text!: string;

        @Prop({ type: String, required: true })
        readonly icon!: string;

        @Prop({ type: String, required: true })
        readonly name!: string;
    };
