
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, NameCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: {
            GenericCallbackRenderer
        }
    })
    export default class KbaChallenge extends Stage {
        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange() {
            this.init();
        }

        // *** Lifecycle Methods ***
        created() {
            this.init();
        }

        // *** Methods ***
        init() {
            // Customize callback component rendering via createElement() data object
            const nameCallbacks: NameCallback[] = this.$util.am.getCallbacksOfType<NameCallback>(this.callbacks, CallbackType.NameCallback);
            for (const nameCallback of nameCallbacks) {
                nameCallback.createElementData = {
                    props: {
                        masked: true,
                        'required-label': this.$t('login.stages.kba-challenge.answer-label')
                    }
                };
            }
        }
    };
