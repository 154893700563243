
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback } from '@forgerock/javascript-sdk';
    import Callback from '@/components/auth/callback/Callback';

    enum MessageType {
        INFORMATION = 0,
        WARNING = 1,
        ERROR = 2
    }

    enum OptionType {
        UNSPECIFIED_OPTION = -1,
        YES_NO_OPTION = 0,
        YES_NO_CANCEL_OPTION = 1,
        OK_CANCEL_OPTION = 2,
    }

    enum DefaultOption {
        YES = 0,
        NO = 1,
        CANCEL = 2,
        OK = 3
    }

    @Component
    export default class ConfirmationCallback extends Callback {
        @Prop()
        callback!: SDKConfirmationCallback;

        // *** Data ***
        MessageType = MessageType;
        OptionType = OptionType;
        DefaultOption = DefaultOption;

        // *** Computed Properties ***
        get defaultOption(): number {
            return this.callback.getDefaultOption();
        }

        get options(): string[] {
            return this.callback.getOptions();
        }

        get optionType(): number {
            return this.callback.getOptionType();
        }

        // *** Methods ***
        isDefaultOption(optionIndex: number): boolean {
            return this.callback.getDefaultOption() === optionIndex;
        }

        submitConfirmation(index: number) {
            this.callback.setInputValue(index);
            const skipValidation = !this.isDefaultOption(index);
            this.$emit('submit-confirmation', skipValidation);
        }

        isFocusable(): boolean {
            return true;
        }

        supportsCallback(callback: FRCallback): boolean {
            return callback && callback.getType() === CallbackType.ConfirmationCallback;
        }
    }
