
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, ChoiceCallback, FRCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
    import NewStage from '@/components/user-self-service/stage/NewStage';
    import GenericCallbackRenderer from '@/components/auth/GenericCallbackRenderer.vue';

    @Component({
        components: {
            GenericCallbackRenderer
        }
    })
    export default class OtpMethodSelection extends NewStage {
        // *** Data ***
        choices: string[] = [];
        choiceCallback: ChoiceCallback | null = null;
        textOutputCallbacks: TextOutputCallback[] = [];

        // *** Computed Properties ***
        get selectedOtpVerificationMethod(): number | null {
            return this.choiceCallback ? this.choiceCallback.getInputValue() as number : 0;
        }

        set selectedOtpVerificationMethod(value) {
            this.choiceCallback && this.choiceCallback.setInputValue(value);
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data
            this.choiceCallback = null;

            // Re-init Data
            try {
                this.choiceCallback = this.$util.am.getCallbackOfType<ChoiceCallback>(this.callbacks, CallbackType.ChoiceCallback);
                this.choices = this.choiceCallback.getChoices().map(choice => {
                    switch (/\d{10}/.test(choice)) {
                        case false:
                            return this.$t('common.otp-selection.email-choice', { email: choice }) as string;
                        case true:
                            return this.$t('common.otp-selection.sms-choice', { phone: this.$util.getFormattedPhone(choice) }) as string;
                        default:
                            return choice;
                    }
                });
                this.selectedOtpVerificationMethod = this.choiceCallback.getDefaultChoice();
            } catch (error) {
                console.error(error);
            }

            this.textOutputCallbacks = this.$util.am.getCallbacksOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);
        }
    }
