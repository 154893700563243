
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, TextInputCallback as SDKTextInputCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import TextInputCallback from '@/components/auth/callback/TextInputCallback.vue';

    @Component({
        components: {
            TextInputCallback
        }
    })
    export default class OnlineBankingRegister extends Stage {
        // *** Data ***
        textInputCallbacks: SDKTextInputCallback[] | null = null;

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange() {
            this.init();
        }

        // *** Lifecycle Methods ***
        created() {
            this.init();
        }

        // *** Methods ***
        init() {
            this.textInputCallbacks = this.$util.am.getCallbacksOfType<SDKTextInputCallback>(this.callbacks, CallbackType.TextInputCallback);
        }
    };
