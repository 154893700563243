
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';

    const ALL_DIGITS_REGEX = /\d/g;
    const PHONE_FORMAT = '(xxx) xxx-xxxx';
    const PHONE_REGEX = /^\(\d{3}\) \d{3}-\d{4}$/;
    const PLACEHOLDER_STRING = 'placeholder-until-next-tick';

    @Component({
        inheritAttrs: false
    })
    export default class VTextFieldPhone extends Vue {
        @Prop({ type: String, required: true })
        readonly value!: string;

        @Prop({ type: Boolean, default: false })
        readonly visited!: boolean;

        @Prop({ type: Boolean, default: false })
        readonly required!: boolean;

        @Prop({ type: String, default: '' })
        readonly requiredLabel!: boolean;

        @Prop({ type: Array, default: () => [] })
        readonly rules!: any[];

        // *** Data ***
        phoneUnformatted = this.value ? this.$util.getUnformattedPhone(this.value) : '';
        syncedVisited = false;

        // *** Computed Properties ***
        get phoneRules(): any[] {
            const regexRule = (v: string) => PHONE_REGEX.test(v) || this.$t('validation.phone-format', { 'phone-format': PHONE_FORMAT });

            let rules: any[];
            if (this.required) {
                let requiredRule;
                if (this.requiredLabel) {
                    requiredRule = (v: string) => !!v || this.$t('validation.required', { label: this.requiredLabel });
                } else if (this.$attrs.label) {
                    requiredRule = (v: string) => !!v || this.$t('validation.required', { label: this.$attrs.label });
                } else {
                    requiredRule = (v: string) => !!v || this.$t('validation.required-simple');
                }
                rules = [requiredRule, regexRule].concat(this.rules);
            } else {
                rules = [regexRule].concat(this.rules);
            }

            return rules;
        }

        get phone(): string {
            if (this.phoneUnformatted === PLACEHOLDER_STRING) {
                return PLACEHOLDER_STRING;
            }

            const up = this.phoneUnformatted; // Un-formatted Phone
            const length = up.length;
            let formattedMobilePhone;

            if (length < 4) {
                formattedMobilePhone = up;
            } else if (length <= 6) {
                formattedMobilePhone = `(${up.substring(0, 3)}) ${up.substring(3)}`;
            } else {
                formattedMobilePhone = `(${up.substring(0, 3)}) ${up.substring(3, 6)}-${up.substring(6, 10)}`;
            }

            return formattedMobilePhone;
        }

        set phone(phone) {
            this.phoneUnformatted = PLACEHOLDER_STRING;
            const result = phone.match(ALL_DIGITS_REGEX);

            this.$nextTick(() => {
                this.phoneUnformatted = result ? result.join('') : '';
            });
        }

        get phoneHint(): string | null {
            return this.syncedVisited && (this.$refs.phone as any).valid
                ? null
                : this.$t('common.contact-info.phone-format-hint', { 'phone-format': PHONE_FORMAT }) as string;
        }

        // *** Watch Methods ***
        @Watch('value')
        onValueChange(newValue: string) {
            this.phone = newValue;
        }

        @Watch('phone')
        onPhoneChange(newValue: string, oldValue: string) {
            if (newValue !== PLACEHOLDER_STRING && newValue !== oldValue) {
                this.$emit('input', newValue);
            }
        }

        @Watch('visited')
        onVisitedChange(newValue: boolean) {
            this.syncedVisited = newValue;
        }

        @Watch('syncedVisited')
        onSyncedVisitedChange(newValue: boolean) {
            if (newValue !== this.visited) {
                this.$emit('update:visited', newValue);
            }
        }
    }
