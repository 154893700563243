
    import Component, { mixins } from 'vue-class-component';
    import { FRStep, CallbackType, HiddenValueCallback } from '@forgerock/javascript-sdk';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import Profile from '@/components/profile/Profile';
    import ProfileMixin from '@/components/profile/common/ProfileMixin';
    import AddContactDialog from '@/components/profile/manage/AddContactDialog.vue';
    import ContactRow from '@/components/profile/manage/ContactRow.vue';
    import RemoveContactDialog from '@/components/profile/manage/RemoveContactDialog.vue';

    @Component({
        components: {
            AddContactDialog,
            ContactRow,
            RemoveContactDialog
        }
    })
    export default class ContactManagement extends mixins(Profile, ProfileMixin) {
        // *** Data ***
        ContactType = ContactType; // Make available to html template

        addContactType: ContactType = ContactType.Sms;
        contact: Contact | null = null;
        contactToRemove: Contact | null = null;
        contacts: Contact[] = [];
        showAddContactDialog = false;
        showRemoveContactDialog = false;

        // *** Computed Properties ***
        get emailContacts(): Record<string, any> {
            return this.contacts.filter((contact: Record<string, any>) => contact.type === ContactType.Email);
        }

        get smsContacts(): Record<string, any> {
            return this.contacts.filter((contact: Record<string, any>) => contact.type === ContactType.Sms);
        }

        // *** Lifecycle Methods ***
        async created() {
            try {
                const response = await this.readContactsReq() as FRStep;
                if (response.callbacks) {
                    const hiddenValueCallbacks = this.$util.am.getCallbacksOfType<HiddenValueCallback>(response.callbacks, CallbackType.HiddenValueCallback);

                    // Get OTP contacts
                    const contactsCallback = hiddenValueCallbacks.find(callback => callback.getInputValue() === 'otp');
                    if (contactsCallback) {
                        this.contacts = this.$util.formatOtpContacts(JSON.parse(contactsCallback.getOutputValue() as string));
                    }
                }
            } catch (error) {
                this.$notification.error({
                    title: this.$t('errors.contact-info-retrieve.title') as string,
                    message: this.$t('errors.contact-info-retrieve.message') as string
                }, error);
            }
        }

        // *** Methods ***
        openAddDialog(contactType: ContactType): void {
            this.addContactType = contactType;
            this.showAddContactDialog = true;
        }

        openRemoveDialog(contact: Contact): void {
            this.contactToRemove = contact;
            this.showRemoveContactDialog = true;
        }

        createContact(contact: Contact): void {
            this.contacts.push(contact);
        }

        updateContact(contact: Contact): void {
            const index = this.contacts.findIndex(c => c.id === contact.id);
            this.contacts[index] = contact;
        }

        async deleteContact(contact: Contact): Promise<void> {
            await this.removeSecurityMethodReq(contact);
            this.contacts = this.contacts.filter(c => c.id !== contact.id);
        }
    };
