
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, ChoiceCallback, FRCallback, TextOutputCallback as SDKTextOutputCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import TextOutputCallback from '@/components/auth/callback/TextOutputCallback.vue';

    @Component({
        components: {
            TextOutputCallback
        }
    })
    export default class SelectAuthMethod extends Stage {
        // *** Data ***
        choices: string[] = [];
        choiceCallback: ChoiceCallback | null = null;
        textOutputCallback: SDKTextOutputCallback | null = null;

        // *** Computed Properties ***
        get selectedMethod(): number | null {
            return this.choiceCallback ? this.choiceCallback.getInputValue() as number : 0;
        }

        set selectedMethod(value) {
            this.choiceCallback && this.choiceCallback.setInputValue(value);
        }

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data
            this.choiceCallback = null;

            // Re-init Data
            try {
                this.choiceCallback = this.$util.am.getCallbackOfType<ChoiceCallback>(this.callbacks, CallbackType.ChoiceCallback);
                this.choices = this.choiceCallback.getChoices();
                this.selectedMethod = this.choiceCallback.getDefaultChoice();
            } catch (error) {
                console.error(error);
            }

            this.textOutputCallback = this.$util.am.getCallbackOfType<SDKTextOutputCallback>(callbacks, CallbackType.TextOutputCallback);
        }

        getLabel(choice: string) {
            switch (choice) {
                case 'OTP':
                    return 'One-time passcode';
                case 'TOTP':
                    return 'Authenticator app';
                case 'WebAuthn':
                    return 'Passkey';
            }
        }
    }
