import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { AxiosResponse } from 'axios';
import merge from 'lodash.merge';

// NOTE: The string values MUST match the route name!
export enum FlowType {
    ForgotPassword = 'ForgotPassword',
    ForgotUsername = 'ForgotUsername',
    Register = 'Register'
}

enum ErrorType {
    IntegerErrorCode,
    SnapshotTokenInvalid,
    Unknown
}

// @ts-ignore
@Component
export default abstract class UserSelfServiceFlow extends Vue {
    @Prop()
    readonly helios!: string; // Used by MX (mobile app)

    @Prop()
    readonly code!: string;

    @Prop()
    readonly token!: string;

    // *** Data ***
    abstract readonly flowType: FlowType;
    abstract readonly stageTypesWithSubStages: Array<string>;
    abstract readonly ussResponseTypeMappings: Record<string, string>;

    readonly realmUrlPath = this.$util.am.getRealmUrlPathFromRealmPath((this.$root as any).config.amRealmPath);
    readonly requiredUssRequestConfig = {
        headers: {
            'Accept-API-Version': 'resource=1.0'
        }
    };

    heliosCopy = false;
    selfServiceRedirectUrls: Record<string, string> = {};
    showTimeoutDialog = false;
    stageName: string | null = null;
    submitInProgress = false;
    ussResponseData: any = {}; // User Self-Service Response Data

    // *** Computed Properties ***
    get requiredUssRequestData(): Record<string, any> {
        const requiredUssRequestData: Record<string, any> = {};

        // Token has to be supplied if available (server uses this keep track of where the user is in the
        // self-service flow). This token changes on each response which is why we always grab the token value
        // from the most recent response.
        if (this.ussResponseData?.token) {
            requiredUssRequestData.token = this.ussResponseData.token;
        }

        return requiredUssRequestData;
    }

    abstract get ussUrl(): string;

    // *** Watch Methods ***
    @Watch('stageName')
    async onStageNameChange(newVal: string) {
        if (newVal) {
            this.$notification.clearAll();
        } else if (newVal === undefined) {
            await this.$notification.error({
                title: this.$t('errors.self-service-stage-name.title') as string,
                message: this.$t('errors.self-service-stage-name.message') as string
            });
            this.$router.push({ name: 'Login' });
        }
    }

    // *** Lifecycle Methods ***
    beforeRouteUpdate(to: Route, from: Route, next: any) {
        // Allows us to be on /forgot-password URL, then manually add any of the accepted query params for testing purposes
        next();
        if (Object.keys(to.query).length > 0) { // Prevents infinite loop between this method and init() which has a $router.replace() call
            this.$nextTick(() => {
                this.init();
            });
        }
    }

    created() {
        this.init();
    }

    // *** Methods ***
    init() {
        if (this.helios) {
            this.heliosCopy = (/true/i).test(this.helios);
            this.getSelfServiceRedirectUrls();
        }

        if (this.code && this.token) {
            // If token and code were provided as query params / props (in email link), continue password reset...
            this.submitRequirements({
                input: {
                    code: this.code
                },
                token: this.token
            }, {});
        } else {
            this.submitInitialRequest();
        }

        // Only run the replace URL call if there are query params that need to be removed from the URL. If we were
        // to run the replace URL call and there aren't any query params, Vue will spit out a duplicate navigation error
        // in the javascript console.
        if (Object.keys(this.$route.query).length > 0) {
            // Remove helios, token, and code query params from the URL so they can't be bookmarked (i.e. this.helios,
            // this.code, and this.token will be undefined after the $router.replace).
            this.$router.replace({ name: this.$route.name as string });
        }
    }

    reload() {
        this.showTimeoutDialog = false;
        this.submitInitialRequest();
    }

    async getSelfServiceRedirectUrls() {
        try {
            const response = await this.$axios.get('/self-service-links');
            this.selfServiceRedirectUrls = response.data;
        } catch (error) {
            this.$notification.error({
                title: this.$t('errors.self-service-init-redirect-urls.title') as string,
                message: this.$t('errors.self-service-init-redirect-urls.message') as string
            }, error);
        }
    }

    async submitInitialRequest() {
        try {
            const ussResponse = await this.$axios.get(this.ussUrl, this.requiredUssRequestConfig);
            this.handleUssResponse(ussResponse);
        } catch (error) {
            this.handleSubmitInitialRequestErrorGeneric(error);
        }
    }

    abstract handleSubmitInitialRequestErrorGeneric(error: any): void;

    async submitRequirements(requestData: Record<string, any>, requestConfigProps: Record<string, any>) {
        const requestConfig = {
            params: {
                _action: 'submitRequirements'
            }
        };

        merge(requestConfig, this.requiredUssRequestConfig, requestConfigProps);
        merge(requestData, this.requiredUssRequestData);

        this.submitInProgress = true;

        try {
            const axiosResponse = await this.$axios.post(this.ussUrl, requestData, requestConfig);
            this.handleUssResponse(axiosResponse);
        } catch (error: any) {
            this.$notification.clearAll(true);
            const errorType = this.getErrorType(error);

            switch (errorType) {
                case ErrorType.SnapshotTokenInvalid: {
                    this.showTimeoutDialog = true;
                    break;
                }
                case ErrorType.IntegerErrorCode: {
                    let errorCode = 'not provided.';

                    if (error?.response?.data?.reason) {
                        errorCode = error.response.data.reason.slice(-2); // Grab last two characters of error reason (ex. 'Error code 20')
                    }

                    this.$notification.error({
                        title: this.$t('errors.error-code.title') as string,
                        message: this.$t('errors.error-code.message', { errorCode }) as string
                    }, error);
                    break;
                }
                default: { // ErrorType.Unknown
                    // Defer to flow-specific error handler by default if error is not handled globally in this component
                    this.handleFlowSpecificError(error);
                    break;
                }
            }
        } finally {
            this.submitInProgress = false;
        }
    }

    abstract handleFlowSpecificError(error: any): void;

    // noinspection JSMethodCanBeStatic
    private getErrorType(error: any): ErrorType {
        if (error?.response?.data) {
            const responseData = error.response.data;

            // We seem to get 'Snapshot token is invalid' when tokens have timed out and 'Invalid token' when
            // self-service tokens are invalidated server-side (e.g. if you change the self-service token lifetime value
            // in the AM admin console, it appears to invalidate all self-service tokens). In both cases, we'll just
            // communicate to the user that it is a session timeout issue.
            if (responseData.code === 400 && (responseData.message === 'Snapshot token is invalid' || responseData.message === 'Invalid token')) {
                return ErrorType.SnapshotTokenInvalid;
            }

            if (responseData?.reason.toLowerCase().includes('error code')) {
                return ErrorType.IntegerErrorCode;
            }
        }

        return ErrorType.Unknown;
    }

    handleUssResponse(ussResponse: AxiosResponse) {
        this.ussResponseData = ussResponse.data;

        const type = this.ussResponseData.type;
        const tag = this.ussResponseData.tag;

        if (this.stageTypesWithSubStages.includes(type)) {
            this.stageName = this.ussResponseTypeMappings[`${type}-${tag}`];
        } else {
            this.ussResponseData = ussResponse.data;
            this.stageName = this.ussResponseTypeMappings[this.ussResponseData.type];
        }
    }

    abstract onCompletion(): void;

    cancel() {
        if (this.heliosCopy) {
            // @ts-ignore
            window.location = this.selfServiceRedirectUrls.heliosFailureUrl;
        } else {
            this.$router.push({ name: 'Login' });
        }
    }
}
