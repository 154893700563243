import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#1eb472',
                'primary-dark': '#147658',
                'primary-light': '#d9ead3',
                secondary: '#2d4655',
                anchor: '#337ab7'
            }
        }
    }
});
