
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';

    // Regex sourced from: https://www.regular-expressions.info/email.html
    const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;

    @Component({
        inheritAttrs: false
    })
    export default class VTextFieldEmail extends Vue {
        @Prop({ type: String, default: '' })
        readonly value!: string;

        @Prop({ type: Boolean, default: false })
        readonly visited!: boolean;

        @Prop({ type: Boolean, default: false })
        readonly required!: boolean;

        @Prop({ type: String, default: '' })
        readonly requiredLabel!: boolean;

        @Prop({ type: Array, default: () => [] })
        readonly rules!: any[];

        // *** Data ***
        syncedVisited = false;

        // *** Computed Properties ***
        get emailRules(): any[] {
            const regexRule = (v: string) => EMAIL_REGEX.test(v) || this.$t('validation.email-format-invalid');

            let rules: any[];
            if (this.required) {
                let requiredRule;
                if (this.requiredLabel) {
                    requiredRule = (v: string) => !!v || this.$t('validation.required', { label: this.requiredLabel });
                } else if (this.$attrs.label) {
                    requiredRule = (v: string) => !!v || this.$t('validation.required', { label: this.$attrs.label });
                } else {
                    requiredRule = (v: string) => !!v || this.$t('validation.required-simple');
                }
                rules = [requiredRule, regexRule].concat(this.rules);
            } else {
                rules = [regexRule].concat(this.rules);
            }

            return rules;
        }

        // *** Watch Methods ***
        @Watch('visited')
        onVisitedChange(newValue: boolean) {
            this.syncedVisited = newValue;
        }

        @Watch('syncedVisited')
        onSyncedVisitedChange(newValue: boolean) {
            if (newValue !== this.visited) {
                this.$emit('update:visited', newValue);
            }
        }
    }
