
    import Component from 'vue-class-component';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';
    import Stage from '@/components/auth/stage/Stage';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class UserHasBeenPwned extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;
        textOutputCallback: TextOutputCallback | null = null;
        description = '';

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
                this.textOutputCallback = this.$util.am.getCallbackOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);
                this.description = this.textOutputCallback.getOutputValue() as string;
            } catch (error) {
                console.error(error);
            }
        }

        submitConfirmation(remindMeLater: boolean) {
            if (!remindMeLater) {
                (this.$root as any).queryParams.goto = process.env.VUE_APP_FEDERATION_URL + '/profile/change-password?goto=' + window.location.href;
            }
            this.next();
        }
    }
