
    import Vue, { VNode } from 'vue';
    import { FRCallback } from '@forgerock/javascript-sdk';
    import merge from 'lodash.merge';

    const CALLBACK_COMPONENTS: any = {};
    const requireCallbackComponent = require.context('./callback', true, /\.vue$/);
    requireCallbackComponent.keys().forEach(filePath => {
        const callbackComponent = requireCallbackComponent(filePath).default;
        // @ts-ignore
        const callbackComponentName = filePath.split('/').pop().replace(/\.vue$/, '');
        CALLBACK_COMPONENTS[callbackComponentName] = callbackComponent;
    });

    export default Vue.extend({
        name: 'GenericCallbackRenderer',

        components: CALLBACK_COMPONENTS,

        props: {
            callbacks: Array as () => FRCallback[] // If we convert to Component Class syntax we could avoid this type trick
        },

        data: function() {
            return {
                availableCallbackComponents: CALLBACK_COMPONENTS,
                callbackCounts: {} as Record<string, number>,
                componentKeyStartNum: 0,
                unknownCallbackErrorShownForCallbackType: {} as Record<string, boolean>
            };
        },

        computed: {
            callbacksToRender: function(): FRCallback[] {
                // Only render callbacks that have been matched to a Vue component
                return this.callbacks.filter(callback => callback.computedType !== undefined);
            }
        },

        watch: {
            callbacks: function(newCallbacks: FRCallback[], oldCallbacks: FRCallback[]) {
                this.componentKeyStartNum += oldCallbacks.length;
                this.init(newCallbacks);
            }
        },

        created: function() {
            this.init(this.callbacks);
        },

        methods: {
            getComputedTypeForCallback: function(callback: FRCallback): string | undefined {
                // This supports rendering a callback by a Vue component that doesn't necessarily match its type.
                // For example, a callback with type 'HiddenValueCallback' could be rendered by a Vue component
                // with a name of 'KBAQuestionGroupsCallback' (doesn't match callback type) instead of a Vue component
                // with a name of 'HiddenValueCallback' (does match callback type).
                let computedCallbackType;
                for (const componentName in this.availableCallbackComponents) {
                    if (this.availableCallbackComponents[componentName].options.methods.supportsCallback(callback)) {
                        computedCallbackType = componentName;
                        if (computedCallbackType !== callback.getType()) {
                            // If the native callback type and computed callback type do not match, break immediately.
                            // For example, if a callback with type of 'HiddenValueCallback' is supported by multiple
                            // Vue components ('HiddenValueCallback' and 'KBASetupCallback'), we want to give preference
                            // to the one that doesn't match the native callback type (i.e. preference would be given to
                            // 'KBAQuestionGroupsCallback', the component with a higher specificity).
                            break;
                        }
                    }
                }

                return computedCallbackType;
            },

            init: function(callbacks: FRCallback[]) {
                this.callbackCounts = {}; // Reset callback counts

                for (const callback of callbacks) {
                    callback.computedType = this.getComputedTypeForCallback(callback);

                    // Show Unknown Callback error if necessary. If the computed callback type is undefined, it means that
                    // none of the available callback Vue components can render the callback.
                    const nativeCallbackType = callback.getType();
                    if (callback.computedType === undefined && !this.unknownCallbackErrorShownForCallbackType[nativeCallbackType]) {
                        this.unknownCallbackErrorShownForCallbackType[nativeCallbackType] = true;
                        this.$notification.error({
                            title: this.$t('errors.unknown-callback.title') as string,
                            message: this.$t('errors.unknown-callback.message', { 'callback-type': nativeCallbackType }) as string
                        });
                    }

                    // Set up the html id's for all the callbacks
                    if (callback.computedType) {
                        let index;
                        if (this.callbackCounts[callback.computedType]) {
                            index = ++this.callbackCounts[callback.computedType];
                        } else {
                            index = this.callbackCounts[callback.computedType] = 1;
                        }

                        callback.htmlId = `${this.$util.camelOrPascalCaseToKebabCase(callback.computedType)}-${index}`;
                    }
                }
            }
        },

        render: function(createElement): VNode {
            let autoFocusElementDetermined = false;
            const callbackVNodes: VNode[] = this.callbacksToRender.map((callback, index) => {
                let autoFocus = false;
                if (!autoFocusElementDetermined &&
                    callback.computedType &&
                    this.availableCallbackComponents[callback.computedType].options.methods.isFocusable()) {
                    autoFocus = true;
                    autoFocusElementDetermined = true;
                }

                // Data Object - See https://vuejs.org/v2/guide/render-function.html#The-Data-Object-In-Depth
                const defaultCreateElementData = {
                    key: this.componentKeyStartNum + index, // The unique key will force a re-render of the component whenever a new group of callbacks is received
                    on: this.$listeners, // Act as transparent wrapper in regards to emitted events
                    props: {
                        autoFocus: autoFocus,
                        callback: callback,
                        id: callback.htmlId
                    }
                };

                // Use lodash merge to merge the default data above with any customized data that has been set on the
                // callback (the overrides/customizations would typically be set up in the stage). Lodash is used
                // because Object.assign() and the spread operator do not perform deep merges.
                return createElement(callback.computedType, merge(defaultCreateElementData, callback.createElementData));
            });

            return createElement('div', callbackVNodes);
        }
    });
