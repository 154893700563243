
    import Component from 'vue-class-component';
    import Stage from '@/components/auth/stage/Stage';
    import { CallbackType, ConfirmationCallback as SDKConfirmationCallback, FRCallback } from '@forgerock/javascript-sdk';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class TCPAAgreement extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data

            // Re-init Data
            try {
                this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
            } catch (error) {
                console.error(error);
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
