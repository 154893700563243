
    import Component from 'vue-class-component';
    import { Watch } from 'vue-property-decorator';
    import { CallbackType, FRCallback, ConfirmationCallback as SDKConfirmationCallback, TextOutputCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import ConfirmationCallback from '@/components/auth/callback/ConfirmationCallback.vue';

    @Component({
        components: {
            ConfirmationCallback
        }
    })
    export default class SecurityCheckup extends Stage {
        // *** Data ***
        confirmationCallback: SDKConfirmationCallback | null = null;

        email = '';
        phone = '';

        // *** Watch Methods ***
        @Watch('callbacks')
        onCallbacksChange(newCallbacks: FRCallback[]) {
            this.initCallbackData(newCallbacks);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Re-init Data
            this.confirmationCallback = this.$util.am.getCallbackOfType<SDKConfirmationCallback>(callbacks, CallbackType.ConfirmationCallback);
            const textOutputCallbacks: TextOutputCallback[] = this.$util.am.getCallbacksOfType<TextOutputCallback>(callbacks, CallbackType.TextOutputCallback);

            if (textOutputCallbacks.length > 0) {
                this.email = textOutputCallbacks[0].getOutputValue() as string;
            }

            if (textOutputCallbacks.length > 1) {
                this.phone = textOutputCallbacks[1].getOutputValue() as string;
            }
        }

        submitConfirmation(skipValidation: boolean) {
            this.next(skipValidation);
        }
    }
