
    import Component from 'vue-class-component';
    import Stage from '@/components/auth/stage/Stage';
    import { CallbackType, ChoiceCallback, FRCallback } from '@forgerock/javascript-sdk';

    @Component
    export default class EStatements extends Stage {
        // *** Data ***
        supportEmail = this.$root.$t('common.trivir-email-general');
        supportTelephone = this.$root.$t('common.trivir-phone-number-general');

        choiceCallback: ChoiceCallback | null = null;

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        rules = [
            (v: any) => typeof v === 'number' || this.$root.$t('validation.required-selection')
        ];

        // *** Computed Properties ***
        get statementDeliverySelection(): number | null {
            return this.choiceCallback ? this.choiceCallback.getInputValue() as number : 0;
        }

        set statementDeliverySelection(value) {
            this.choiceCallback && this.choiceCallback.setInputValue(value);
        }

        // *** Lifecycle Methods ***
        created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            // Reset Data

            // Re-init Data
            try {
                this.choiceCallback = this.$util.am.getCallbackOfType<ChoiceCallback>(callbacks, CallbackType.ChoiceCallback);
            } catch (error) {
                console.error(error);
            }
        }
    }
