
    import Vue from 'vue';
    import { FRStep } from '@forgerock/javascript-sdk';

    const STAGE_COMPONENTS: any = {};
    const requireStageComponent = require.context('./stage', false, /\.vue$/);
    const forgotPasswordComponents = require.context('./stage/forgot-password', false, /\.vue$/);
    const forgotUsernameComponents = require.context('./stage/forgot-username', false, /\.vue$/);
    const registerComponents = require.context('./stage/register', false, /\.vue$/);
    const sharedComponents = require.context('./stage/shared', false, /\.vue$/);

    requireStageComponent.keys().forEach(filePath => {
        const stageComponent = requireStageComponent(filePath).default;
        // @ts-ignore
        const stageComponentName = filePath.split('/').pop().replace(/\.vue$/, '');
        STAGE_COMPONENTS[stageComponentName] = stageComponent;
    });

    forgotPasswordComponents.keys().forEach(filePath => {
        const stageComponent = forgotPasswordComponents(filePath).default;
        // @ts-ignore
        const stageComponentName = filePath.split('/').pop().replace(/\.vue$/, '');
        STAGE_COMPONENTS[stageComponentName] = stageComponent;
    });

    forgotUsernameComponents.keys().forEach(filePath => {
        const stageComponent = forgotUsernameComponents(filePath).default;
        // @ts-ignore
        const stageComponentName = filePath.split('/').pop().replace(/\.vue$/, '');
        STAGE_COMPONENTS[stageComponentName] = stageComponent;
    });

    registerComponents.keys().forEach(filePath => {
        const stageComponent = registerComponents(filePath).default;
        // @ts-ignore
        const stageComponentName = filePath.split('/').pop().replace(/\.vue$/, '');
        STAGE_COMPONENTS[stageComponentName] = stageComponent;
    });

    sharedComponents.keys().forEach(filePath => {
        const stageComponent = sharedComponents(filePath).default;
        // @ts-ignore
        const stageComponentName = filePath.split('/').pop().replace(/\.vue$/, '');
        STAGE_COMPONENTS[stageComponentName] = stageComponent;
    });

    export default Vue.extend({
        name: 'StageViewer',

        components: STAGE_COMPONENTS,

        props: {
            step: Object as () => FRStep // If we convert to Component Class syntax we could avoid this type trick
        },

        data: () => ({
            forceGenericRendering: false // This was added so we can easily toggle generic rendering in Vue browser debug tools (for testing purposes)
        }),

        computed: {
            knownStages: function(): Array<string> {
                return Object.keys(STAGE_COMPONENTS);
            },

            stageName: function(): string {
                let stageName = this.step.payload.stage || this.stageNameFromStageNode;
                if (this.forceGenericRendering || !this.knownStages.includes(stageName as string)) {
                    // Use the default stage (i.e. generic rendering) if the provided stage name does not match
                    // up with any of the stage components or if generic rendering has been forced.
                    if (!this.forceGenericRendering) {
                        console.debug(`Component for stage '${stageName}' does not exist. Defaulting to generic rendering.`);
                    }
                    stageName = 'Default';
                }
                return stageName as string;
            },

            // Stage name from 'Stage Node' auth tree node (TriVir workaround for versions of AM prior to 7 that don't have the stage name feature)
            stageNameFromStageNode: function(): string | undefined {
                return this.$util.am.getStageNameFromCallbacks(this.step.callbacks);
            }
        },

        watch: {
            forceGenericRendering: function() {
                for (const callback of this.step.callbacks) {
                    // Fixes stale data issue when dynamically enabling/disabling generic rendering (via forceGenericRendering var)
                    callback.createElementData = {};
                }
            }
        }
    });
