
    import Vue from 'vue';
    import Panzoom from '@panzoom/panzoom';

    export default Vue.extend({
        name: 'TreeDisplay',

        // *** Data ***
        data() {
            return {
                adminSessionTokenId: '',
                am: '',
                amAdminUsername: 'dberry@trivir.com',
                amAdminPassword: 'vuk3gqk3dbu-vkr9FWP',
                currentNode: null as any,
                currentStage: '' as string | undefined,
                currentTree: Object as Record<string, any>,
                outerTrees: [] as Array<string>,
                zoom: 1.25
            };
        },

        // *** Computed Properties ***
        computed: {
            step(): Record<string, any> {
                return this.$store.state.step;
            },

            getOptions(): any {
                return {
                    method: 'GET',
                    headers: {
                        [(this.$root as any).config.amCookie]: this.adminSessionTokenId
                    }
                };
            },

            vueCanvas(): HTMLCanvasElement {
                return this.$refs.canvas as HTMLCanvasElement;
            },

            ctx(): CanvasRenderingContext2D | null {
                if (this.vueCanvas) {
                    return this.vueCanvas.getContext('2d') as CanvasRenderingContext2D;
                }
                return null;
            },

            currentTreeName(): string {
                return this.currentTree._id;
            }
        },

        // *** Watch Methods ***
        watch: {
            async step() {
                if (Object.keys(this.currentTree).length > 0) {
                    this.currentStage = this.step.payload.stage;
                    await this.loadTree();
                    this.renderTree();
                }
            },

            zoom() {
                this.renderTree();
            }
        },

        // *** Lifecycle Methods ***
        async created() {
            this.am = 'https://openam-trivir-webinar.forgeblocks.com/' + (this.$root as any).config.amDeployContext;

            if (this.step?.payload?.stage) {
                this.currentStage = this.step.payload.stage;
            }

            try {
                await this.authenticate();
                const currentTreeName = await this.getDefaultTreeName();
                this.currentTree = await this.getTree(currentTreeName);
                await this.loadTree();
                this.renderTree();
            } catch (error) {
                console.error(error);
            }
        },

        mounted() {
            Panzoom(this.vueCanvas, {
                canvas: true,
                disableYAxis: true
            });
        },

        // *** Methods ***
        methods: {
            async authenticate() {
                const url = this.am + '/json/authenticate';

                try {
                    // The first step in the authentication is just username and password
                    let res = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-OpenAM-Username': this.amAdminUsername,
                            'X-OpenAM-Password': this.amAdminPassword,
                            'Accept-API-Version': 'resource=2.0, protocol=1.0'
                        }
                    });

                    let data = await res.json();

                    // The first step returns callbacks to let the user setup or skip MFA
                    data.callbacks[2].input[0].value = 'Skip'; // We want to skip this, so we need to send a value of 'Skip' in the input

                    res = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept-API-Version': 'resource=2.0, protocol=1.0'
                        },
                        body: JSON.stringify(data)
                    });

                    data = await res.json();

                    this.adminSessionTokenId = data.tokenId;

                    // Need to delete cookie after getting session token so we can log in as another user
                    document.cookie = `${(this.$root as any).config.amCookie}=; Domain=.trivir.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
                } catch (error) {
                    console.error(error);
                    throw new Error('Unable to get admin SSO token');
                }
            },

            // Load specific tree
            async loadTree() {
                try {
                    // If currentNode does not exist, this means it's the first time loadTree is being ran
                    if (!this.currentNode) {
                        this.currentNode = this.currentTree.nodes[this.currentTree.entryNodeId];
                    } else if (this.currentNode.displayName === 'Push Registration Options Page') {
                        // Means the next node will be Push Registration
                        const nodes = Object.values(this.currentTree.nodes);
                        this.currentNode = nodes.find((node: any) => node.displayName === 'Push Registration');
                    } else if (!this.currentStage) {
                        // Push Registration should be the only node without a stage
                        // If current node is Push Registration, it's polling, so we don't want to reload the tree
                        return;
                    } else {
                        this.currentNode = await this.getCurrentNode(this.currentTree);
                    }

                    // Check if first node of currentTreeName is an inner tree, if so set currentTreeName to inner tree
                    if (this.currentNode.nodeType === 'InnerTreeEvaluatorNode' && this.currentNode.displayName.split('Page')[0].replace(' ', '') !== this.currentTreeName) {
                        await this.setOuterTree();
                    }
                } catch (error) {
                    console.error(error);
                    throw new Error('Unable to load tree');
                }

                // This is for debugging
                // console.log(`currentTree: ${this.currentTree?._id}`);
                // console.log(`curretNode: ${this.currentNode?.displayName}`);
                // console.log(`currentStage: ${this.currentStage}`);
            },

            // If currentNode is a InnerTreeEvaluatorNode, we need to get the tree it represents
            async setOuterTree() {
                this.outerTrees.push(this.currentTreeName);
                this.currentTree = await this.getTree(this.currentTreeName);
                this.currentNode = await this.getCurrentNode(this.currentTree);
            },

            // Returns current node
            async getCurrentNode(currentTree: Record<string, any>): Promise<any> {
                // Check current tree to see if any nodes match the current stage
                for (const key of Object.keys(currentTree.nodes)) {
                    const matches = await this.nodeMatchesStage(key, currentTree.nodes[key].nodeType);
                    if (matches) {
                        return currentTree.nodes[key];
                    }
                }

                // If none of the current tree's nodes match, look at the inner trees
                const innerTrees = [];
                const innerTreeNodesKeys = Object.keys(currentTree.nodes).filter(key => currentTree.nodes[key].nodeType === 'InnerTreeEvaluatorNode');
                const innerTreeNodes = innerTreeNodesKeys.map(key => Object.assign(currentTree.nodes[key], { key }));

                if (innerTreeNodes.length > 0) {
                    for (const innerTreeNode of innerTreeNodes) {
                        const node = await this.getNode(innerTreeNode.key, innerTreeNode.nodeType);
                        const tree = await this.getTree(node.tree);
                        innerTrees.push(tree);
                    }
                }

                // Look at the Page Nodes for each innerTree and see if any of them match the stage name
                if (innerTrees.length > 0) {
                    for (const innerTree of innerTrees) {
                        // Filter out all nodes that are not of type Page Node
                        const pageNodeIds = Object.keys(innerTree.nodes).filter(key => innerTree.nodes[key].nodeType === 'PageNode');

                        // If any Page Nodes exist, find the one that matches the current stage
                        if (pageNodeIds.length > 0) {
                            for (const nodeId of pageNodeIds) {
                                if (await this.nodeMatchesStage(nodeId, 'PageNode')) {
                                    this.outerTrees.push(currentTree._id);
                                    this.currentTree = innerTree;
                                    return innerTree.nodes[nodeId];
                                }
                            }
                        }
                    }
                }

                // Back out of inner tree
                if (this.outerTrees.length > 0) {
                    const treeName = this.outerTrees.pop();
                    this.currentTree = await this.getTree(treeName!);
                    return await this.getCurrentNode(this.currentTree);
                }

                return null;
            },

            // Check to see if a node's stage matches the current stage
            async nodeMatchesStage(id: string, nodeType: string) {
                if (nodeType === 'SuccessNode' || nodeType === 'FailureNode') {
                    return nodeType === this.currentStage + 'Node';
                }

                const node = await this.getNode(id, nodeType);

                if (node.stage) {
                    return node.stage === this.currentStage;
                }

                return false;
            },

            // Gets the complete node
            async getNode(id: string, nodeType: string) {
                const url = this.am + '/json/realms' + (this.$root as any).config.amRealmPath + `/realm-config/authentication/authenticationtrees/nodes/${nodeType}/${id}`;

                try {
                    const res = await fetch(url, this.getOptions);
                    return await res.json();
                } catch (error) {
                    console.error(error);
                }
            },

            // Gets tree data
            async getTree(treeName: string) {
                const url = this.am + '/json/realms' + (this.$root as any).config.amRealmPath + '/realm-config/authentication/authenticationtrees/trees/' + treeName + '?forUI=true';

                try {
                    const res = await fetch(url, this.getOptions);
                    const data = await res.json();

                    for (const key of Object.keys(data.nodes)) {
                        const node = data.nodes[key];
                        // If node is of type PageNode, add pages
                        if (node.nodeType === 'PageNode') {
                            const res = await this.getNode(key, 'PageNode');
                            data.nodes[key].nodes = res.nodes;
                            // If node is of type StageNode, add stage name
                        } else if (node.nodeType === 'StageNode') {
                            const res = await this.getNode(key, 'StageNode');
                            data.nodes[key].stage = res.stage;
                        }
                    }

                    // Hardcoded success/failure nodes
                    data.nodes['70e691a5-1e33-4ac3-a356-e7b6d60d92e0'] = { displayName: 'Success', nodeType: 'SuccessNode', connections: {}, _outcomes: [] };
                    data.nodes['e301438c-0bd0-429c-ab0c-66126501069a'] = { displayName: 'Failure', nodeType: 'FailureNode', connections: {}, _outcomes: [] };

                    return data;
                } catch (error) {
                    console.error(error);
                    throw new Error('Unable to get tree data');
                }
            },

            // Gets name of the default tree for the realm
            async getDefaultTreeName() {
                const url = this.am + '/json/realms/root/realms' + (this.$root as any).config.amRealmPath + '/realm-config/authentication';

                try {
                    const res = await fetch(url, this.getOptions);
                    const data = await res.json();
                    return data.core.orgConfig;
                } catch (error) {
                    console.error(error);
                    throw new Error('Unable to get default tree name');
                }
            },

            // *** Methods for rendering tree to canvas ***

            // Positions node on canvas
            positionNode(node: any, id: string, x: number, y: number) {
                const verticalGap = 180;
                const horizontalGap = 60;

                node.position = { x, y };

                const maxWidth = this.calcMaxWidth(node);
                node.width = maxWidth;

                let height = this.calcHeight(node);
                node.height = height;

                if (x + maxWidth > this.currentTree.size.width) {
                    this.currentTree.size.width = (x + maxWidth + 50);
                }

                if (y + height > this.currentTree.size.height) {
                    this.currentTree.size.height = (y + height + 50);
                }

                x += maxWidth + horizontalGap;

                height = 0;
                if (node.nodeType === 'PageNode') {
                    for (const key of Object.keys(node.connections)) {
                        const connNode: any = this.currentTree.nodes[node.connections[key]];
                        if (connNode && !connNode.position) {
                            height += verticalGap + this.positionNode(connNode, node.connections[key], x, Number(y + height));
                        }
                    }
                } else {
                    for (const outcome of node._outcomes) {
                        if (outcome.id) {
                            const connNode: any = this.currentTree.nodes[node.connections[outcome.id]];
                            if (connNode && !connNode.position) {
                                height += verticalGap + this.positionNode(connNode, node.connections[outcome.id], x, Number(y + height));
                            }
                        }
                    }
                }

                return (height > 0) ? Number(height - verticalGap) : height;
            },

            calcHeight(node: any) {
                let height = 50;
                if (node.nodeType === 'PageNode') {
                    height += node.nodes.length * 30;
                } else {
                    height += node._outcomes.length * 20;
                }

                return height;
            },

            calcMaxWidth(node: any) {
                let maxWidth = 0;
                if (this.ctx) {
                    this.ctx.font = '8px Arial';
                    const nodeTypeTextWidth = this.ctx.measureText(node.nodeType).width;

                    this.ctx.font = 'bold 12px Arial';
                    const nodeNameTextWidth = this.ctx.measureText(node.displayName).width;

                    // Sets max width based on width of nodeType and displayName
                    maxWidth = Math.max(nodeTypeTextWidth, nodeNameTextWidth);

                    // If anything has wider text, increase maxWidth
                    if (node.nodeType === 'PageNode') {
                        for (const treeNode in this.currentTree.nodes) {
                            if ((this.ctx.measureText(this.currentTree.nodes[treeNode].displayName).width + 40) > maxWidth) {
                                maxWidth = Math.round(this.ctx.measureText(this.currentTree.nodes[treeNode].displayName).width + 40);
                            }
                        }
                    } else {
                        for (const outcome of node._outcomes) {
                            if ((this.ctx.measureText(outcome.displayName).width) > maxWidth) {
                                maxWidth = Math.round(this.ctx.measureText(outcome.displayName).width);
                            }
                        }
                    }
                }

                return maxWidth;
            },

            // Positions tree on canvas
            positionTree() {
                if (!this.currentTree.size) {
                    this.currentTree.size = { width: 0, height: 0 };
                }

                this.positionNode(this.currentTree.nodes[this.currentTree.entryNodeId], this.currentTree.entryNodeId.toString(), 4, 4);
            },

            // Draws lines connecting nodes
            drawLine(a: number, b: number, x: number, y: number) {
                if (this.ctx) {
                    this.ctx.beginPath();
                    this.ctx.moveTo(a, b);
                    const diff = (Math.abs(a - x) / 3);
                    this.ctx.bezierCurveTo(a + diff, b, x - diff, y, x, y);
                    this.ctx.lineTo(x - 3, y - 3);
                    this.ctx.lineTo(x, y);
                    this.ctx.lineTo(x - 3, y + 3);
                    this.ctx.strokeStyle = 'lightgrey';
                    this.ctx.stroke();
                }
            },

            // Draws output circles on nodes
            drawCircle(x: number, y: number) {
                if (this.ctx) {
                    this.ctx.beginPath();
                    this.ctx.arc(x, y, 6, 0, 2 * Math.PI, false);
                    this.ctx.fillStyle = 'white';
                    this.ctx.fill();
                    this.ctx.lineWidth = 2;
                    this.ctx.strokeStyle = '#039be5';
                    this.ctx.stroke();
                }
            },

            // Draws node to canvas
            async drawNode(key: string, node: any) {
                const isCurrentNode = node.displayName === this.currentNode.displayName;

                // Ignore unattached nodes
                if (node.position && this.ctx) {
                    const right = node.width + 20;
                    const center = node.position.x + (right / 2);
                    const height = node.height;

                    // Node container styles
                    if (isCurrentNode && node.displayName === 'Success') {
                        this.ctx.fillStyle = '#e8f5e9';
                        this.ctx.strokeStyle = '#388e3c';
                    } else if (isCurrentNode && node.displayName === 'Failure') {
                        this.ctx.fillStyle = '#d32f2f';
                        this.ctx.strokeStyle = '#ffebee';
                    } else if (isCurrentNode) {
                        this.ctx.fillStyle = '#e1f5fe';
                        this.ctx.strokeStyle = '#0288d1';
                    } else {
                        this.ctx.fillStyle = '#fafafa';
                        this.ctx.strokeStyle = '#616161';
                    }
                    this.ctx.lineWidth = 1;
                    this.ctx.fillRect(node.position.x + 2, node.position.y + 2, right - 4, height - 4);
                    this.ctx.strokeRect(node.position.x, node.position.y, right, height);

                    // Node text styles
                    this.ctx.fillStyle = '#212121';
                    this.ctx.font = 'bold 12px Arial';
                    this.ctx.textAlign = 'center';
                    this.ctx.fillText(node.displayName, center, node.position.y + 20);

                    this.ctx.font = '9px Arial';
                    this.ctx.fillText(node.nodeType, center, node.position.y + 34);

                    // Page styles
                    if (node.nodeType === 'PageNode') {
                        let y = node.position.y + 65;

                        // Draw pages within PageNode
                        for (const page of node.nodes) {
                            this.ctx.fillStyle = '#212121';
                            this.ctx.font = 'bold 10px Arial';
                            this.ctx.fillText(page.displayName, center, y - 6);
                            this.ctx.strokeRect(node.position.x + 20, y - 20, node.width - 20, 20);
                            y += 30;
                        }

                        this.drawCircle(node.position.x + right, y - 30);

                        for (const key of Object.keys(node.connections)) {
                            const outcomeNode: any = this.currentTree.nodes[node.connections[key]];
                            const midpoint = outcomeNode.position.y + (outcomeNode.height / 2);
                            this.drawLine(node.position.x + right + 7, y - 30, outcomeNode.position.x - 3, midpoint);
                        }
                    } else {
                        let y = node.position.y + 55;

                        // Draw connections
                        for (const outcome of node._outcomes) {
                            this.ctx.font = '12px Arial';
                            this.ctx.fillStyle = '#757575';
                            this.ctx.textAlign = 'right';
                            this.ctx.fillText(outcome.displayName, node.position.x + right - 10, y);

                            this.drawCircle(node.position.x + right, y - 4);

                            const outcomeNode: any = this.currentTree.nodes[node.connections[outcome.id]];
                            if (outcomeNode?.position) {
                                const midpoint = outcomeNode.position.y + (outcomeNode.height / 2);
                                this.drawLine(node.position.x + right + 7, y - 4, outcomeNode.position.x - 3, midpoint);
                            }
                            y += 20;
                        }
                    }
                }
            },

            // Iterates through each node and renders tree
            renderTree() {
                if (this.ctx) {
                    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
                    this.ctx.beginPath();

                    this.positionTree();

                    this.vueCanvas.width = this.currentTree.size.width * this.zoom;
                    this.vueCanvas.height = this.currentTree.size.height * this.zoom;
                    this.ctx.scale(this.zoom, this.zoom);

                    for (const key of Object.keys(this.currentTree.nodes)) {
                        const node = this.currentTree.nodes[key];
                        this.drawNode(key, node);
                    }
                }
            }
        }
    });
