
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop, Watch } from 'vue-property-decorator';
    import { Contact, ContactType } from '@/components/profile/common/Types';
    import VCardTitleWithClose from '@/components/profile/common/dialog/VCardTitleWithClose.vue';

    @Component({
        components: {
            VCardTitleWithClose
        }
    })
    export default class OtpVerificationCard extends Vue {
        @Prop({ type: Boolean, required: true })
        codeInvalid!: boolean;

        @Prop({ type: Object, required: true })
        contact!: Contact;

        @Prop({ type: Boolean, required: true })
        enableBackButton!: boolean;

        @Prop({ type: Boolean, required: true })
        submitInProgress!: boolean;

        // *** Data ***
        code = '';
        codeHint = '';
        codeRulesDeactivated = false;
        firstBlur = true;
        showInvalidCodeError = false;
        valid = true;

        // *** Computed Properties ***
        get rules(): Array<any> {
            return this.codeRulesDeactivated
                ? []
                : [
                    () => !(this.showInvalidCodeError && this.codeInvalid) || this.$t('validation.otp-code-invalid'),
                    (v: any) => !!v || this.$t('validation.required', { label: this.$t('common.otp-verification.code-label-short') })
                ];
        }

        get verifyingOtpViaEmail(): boolean {
            return this.contact.type === ContactType.Email;
        }

        get verifyingOtpViaSms(): boolean {
            return this.contact.type === ContactType.Sms;
        }

        get contactId(): string {
            const contactId = this.contact.id as string;
            return this.contact.type === ContactType.Sms ? this.$util.getFormattedPhone(contactId) : contactId;
        }

        // *** Watch Methods ***
        @Watch('codeInvalid')
        onCodeInvalidChange(newVal: boolean) {
            if (newVal) {
                this.onCodeInvalidOrContactChange();
            }
        }

        @Watch('contact')
        onContactChange() {
            this.onCodeInvalidOrContactChange();
        }

        // *** Methods ***
        onCodeInvalidOrContactChange() {
            this.code = '';
            this.showInvalidCodeError = this.codeInvalid;
            (this.$refs.code as any).focus();
        }

        onBlur(event: any) {
            this.showInvalidCodeError = false;

            if ((event.relatedTarget && (event.relatedTarget.id === 'dialog-btn-cancel' || event.relatedTarget.id === 'dialog-btn-back' || event.relatedTarget.id === 'resend-link')) || (this.firstBlur && !this.enableBackButton)) {
                this.codeRulesDeactivated = true;
            } else {
                this.codeRulesDeactivated = false;
                // This is needed because the invalid code error needs to be replaced by 'code required' message instead
                // when losing focus.
                (this.$refs.form as any).validate();
            }

            this.firstBlur = false;
        }

        onInput() {
            this.codeHint = '';
            this.showInvalidCodeError = false;
            this.codeRulesDeactivated = false;
        }

        back() {
            if (!this.submitInProgress) {
                this.$emit('back');
            }
        }

        cancel() {
            this.$emit('cancel');
        }

        resendCode() {
            if (!this.submitInProgress) {
                this.$emit('resend');
                (this.$refs.code as any).focus();
                this.code = '';
                this.codeHint = this.$t('common.otp-verification.resent-message') as string;
                this.codeRulesDeactivated = true;
            }
        }

        activateRulesThenNext() {
            this.codeRulesDeactivated = false;
            this.$nextTick(() => {
                (this.$refs.form as any).validate();
                if (this.valid && !this.submitInProgress) {
                    this.$emit('next', this.code);
                }
            });
        }
    }
