
    import Component from 'vue-class-component';
    import { CallbackType, FRCallback, PasswordCallback as SDKPasswordCallback } from '@forgerock/javascript-sdk';
    import Stage from '@/components/auth/stage/Stage';
    import NewAndConfirmPasswordFields from '@/components/common/NewAndConfirmPasswordFields.vue';
    import PasswordCallback from '@/components/auth/callback/PasswordCallback.vue';

    @Component({
        components: {
            NewAndConfirmPasswordFields,
            PasswordCallback
        }
    })
    export default class ChangePassword extends Stage {
        // *** Data ***
        currentPassword: SDKPasswordCallback | null = null;
        newPassword: SDKPasswordCallback | null = null;
        confirmNewPassword: SDKPasswordCallback | null = null;

        // *** Lifecycle Methods ***
        async created() {
            this.initCallbackData(this.callbacks);
        }

        // *** Methods ***
        initCallbackData(callbacks: FRCallback[]) {
            try {
                const passwordCallbacks = this.$util.am.getCallbacksOfType<SDKPasswordCallback>(callbacks, CallbackType.PasswordCallback);
                this.currentPassword = passwordCallbacks[0];
                this.newPassword = passwordCallbacks[1];
                this.confirmNewPassword = passwordCallbacks[2];
            } catch (error) {
                console.error(error);
            }
        }

        async save() {
            if (!this.valid) {
                (this.$refs.form as any).validate();
            } else {
                try {
                    this.next();
                } catch (error) {
                    this.$notification.error({
                        title: this.$t('errors.password-change.title') as string,
                        message: this.$t('errors.password-change.message') as string
                    }, error);
                }
            }
        }
    }
