
    import Component from 'vue-class-component';
    import Stage from '@/components/user-self-service/stage/Stage';

    @Component
    export default class EnterUserDetails extends Stage {
        // *** Data ***
        usernameLabel = this.$root.$t('forgot-password.enter-user-details.username-label');
        username = '';

        // See NOTE in @/i18n.ts for why this.$root.$t() is used here instead of this.$t()
        rules = [
            (v: any) => !!v || this.$root.$t('validation.required', { label: this.usernameLabel })
        ];

        // *** Methods ***
        getInputData(): Record<string, any> {
            return {
                queryFilter: `uid eq "${this.username}"`
            };
        }

        cancel() {
            if (!this.submitInProgress) {
                this.$emit('cancel');
            }
        }
    }
