import { PluginObject } from 'vue';
import Axios from 'axios';

const AxiosPlugin: PluginObject<any> = Object.freeze({
    install: function(Vue, options) {
        Vue.prototype.$axios = Axios.create(options);
    }
});

export default AxiosPlugin;
